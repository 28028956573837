<h3 class="m-t-0 m-b-20 inline-block titulo-buscador" *ngIf="tipo == 'dashboard'">
    Encuentra aquí la licitación que buscas <br><br>
    <span (click)="MostrarMapa()" id="button-mostrar-mapa">
        Mostrar mapa
        <i class="fa fa-map-marker"></i>
    </span>
</h3>
<div class="input-group width-100">
    <input id="filter-search" type="search" placeholder="Expediente, localidad, estado ..." class="form-control" autocomplete="off"
        (focus)="MostrarComboBuscar()" 
        (keyup)="Buscar($event)" 
        (keyup.enter)="CargarTodosLosResultados()" 
        [(ngModel)]="busqueda">
    <div class="input-group-append cursor" (click)="CargarTodosLosResultados()">
    <span class="input-group-addon" style="background: #072861;"><i class="fa fa-search" style="color: white;"></i></span>
    </div>
</div>
<div id="content-loading" style="display: none;" *ngIf="tipo != 'mis-obras'">
    <p><img src="assets/loading.gif" style="margin-right: 10px; width: 70px;">Cargando datos...</p>
</div>
<div *ngIf="resultados" id="content-search">
    <div *ngIf="resultados.generales.length">
        <div style="padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 11px;
            background: rgb(7, 40, 97);
            color: white;">Nombre licitaciones:</div>
        <div>
            <div class="hover-search" style="cursor: pointer; color: #e34d63; font-weight: 500;" (click)="CargarTodosLosResultados()">Ver los {{resultados.total}} resultados</div>
        </div>
        <div class="display-6-elements" *ngFor="let item of resultados.generales">
            <a [routerLink]="['/licitacion/id:'+item.id]">
                <div class="hover-search" [innerHTML]="item.objeto_contrato" style="cursor: pointer;"></div>
            </a>
        </div>
    </div>
    <div *ngIf="resultados.localidades.length">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Localidad:</div>
        <div *ngFor="let item of resultados.localidades">
            <div class="hover-search" style="cursor: pointer;" (click)="AplicarFiltro(item.localidad, filtros.estado)">{{item.localidad}}</div>
        </div>
    </div>
    <div *ngIf="resultados.organismos.length">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Organismos:</div>
        <div *ngFor="let item of resultados.organismos">
            <div class="hover-search" style="cursor: pointer;" (click)="AbrirOrganismo(item.organismo)">{{item.organismo}}</div>
        </div>
    </div>
    <div *ngIf="resultados.adjudicatarios.length">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Adjudicatarios:</div>
        <div *ngFor="let item of resultados.adjudicatarios">
            <div class="hover-search" style="cursor: pointer;" (click)="AbrirAdjudicatario(item.adjudicatario)">{{item.adjudicatario}}</div>
        </div>
    </div>
    <div *ngIf="resultados.estados.length > 0 && tipo != 'adjudicaciones'">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Estado:</div>
        <div *ngFor="let item of resultados.estados">
            <div class="hover-search" style="cursor: pointer;" (click)="AplicarFiltro(filtros.localidad, item.estado)">{{item.estado}}</div>
        </div>
    </div>
    <div *ngIf="resultados.expedientes.length">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Expediente:</div>
        <div *ngFor="let item of resultados.expedientes">
            <a [routerLink]="['/licitacion/id:'+item.id]">
                <div class="hover-search" style="cursor: pointer;">{{item.expediente}}</div>
            </a>
        </div>
    </div>
</div>
<ng-container *ngIf="filtros.provincia_codigo == '' && filtros.localidad == '' && filtros.estado == '' && filtros.orden == '' && filtros.busqueda == ''">
    <h3 id="title-content-licitaciones-cards" *ngIf="tipo == 'dashboard'">Licitaciones recientes</h3>
</ng-container>
<h3 id="title-content-licitaciones-cards" *ngIf="tipo == 'mis-obras'"></h3>
<div *ngIf="filtros.provincia_codigo != '' || filtros.localidad != '' || filtros.estado != '' || filtros.orden != '' || filtros.busqueda != ''" 
    id="content-licitaciones-filters">
    <div *ngIf="filtros.provincia_codigo != ''" class="licitaciones-filters" (click)="ClearFiltro('provincia_codigo')" style="margin-bottom: 5px;">Provincia: {{filtros.provincia}} <i class="fa fa-times-circle m-l-5"></i></div>
    <div *ngIf="filtros.localidad != ''" class="licitaciones-filters" (click)="ClearFiltro('localidad')" style="margin-bottom: 5px;">Localidad: {{filtros.localidad}} <i class="fa fa-times-circle m-l-5"></i></div>
    <div *ngIf="filtros.estado != ''" class="licitaciones-filters" (click)="ClearFiltro('estado')" style="margin-bottom: 5px;">Estado: {{filtros.estado}} <i class="fa fa-times-circle m-l-5"></i></div>
    <div *ngIf="filtros.orden != ''" class="licitaciones-filters" (click)="ClearFiltro('orden')" style="margin-bottom: 5px;">Orden: {{filtros.orden}} <i class="fa fa-times-circle m-l-5"></i></div>
    <div *ngIf="filtros.busqueda != ''" class="licitaciones-filters" (click)="ClearFiltro('busqueda')" style="margin-bottom: 5px;">Búsqueda: {{filtros.busqueda}} <i class="fa fa-times-circle m-l-5"></i></div>
</div>
<div class="clearfix"></div>
<div class="content-buttons-ord-filt">
    <div style="float: left; margin-left: 0px; background: #072861; cursor: default">
        Mostrando {{params.total}} resultados:
    </div>
    <div (click)="MostrarFiltroEstado()" *ngIf="tipo != 'adjudicaciones'" class="relative">Filtrar por estado
        <div id="content-estados-selector" class="selector-filtros">
            <div (click)="AplicarFiltro(filtros.localidad, 'Anuncio previo (PRE)', filtros.orden)">Anuncio previo (PRE)</div>
            <div (click)="AplicarFiltro(filtros.localidad, 'En plazo (PUB)', filtros.orden)">En plazo (PUB)</div>
            <div (click)="AplicarFiltro(filtros.localidad, 'Pendiente de adjudicación (EV)', filtros.orden)">Pendiente de adjudicación (EV)</div>
            <div (click)="AplicarFiltro(filtros.localidad, 'Adjudicada (ADJ)', filtros.orden)">Adjudicada (ADJ)</div>
            <div (click)="AplicarFiltro(filtros.localidad, 'Resuelta (RES)', filtros.orden)">Resuelta (RES)</div>
        </div>
    </div>
    <div (click)="MostrarFiltroOrden()" class="relative">Ordenar por
        <div id="content-orden-selector" class="selector-filtros">
            <div (click)="AplicarFiltro(filtros.localidad, filtros.estado, 'Defecto')">Defecto</div>
            <div (click)="AplicarFiltro(filtros.localidad, filtros.estado, 'Nombre')">Nombre</div>
            <div (click)="AplicarFiltro(filtros.localidad, filtros.estado, 'Recientes')">Recientes</div>
            <div (click)="AplicarFiltro(filtros.localidad, filtros.estado, 'Cuantía ascendiente')">Importe ascendiente</div>
            <div (click)="AplicarFiltro(filtros.localidad, filtros.estado, 'Cuantía descendiente')">Importe descendiente</div>
        </div>
    </div>
    <div id="content-loading-2" style="display: none;">
        <p><img src="assets/loading.gif" style="margin-right: 10px; width: 70px;">Cargando datos...</p>
    </div>
</div>
<div class="clearfix"></div>
<div id="content-licitaciones-cards" *ngIf="tipo == 'dashboard'">
    <div  *ngFor="let item of licitaciones">
        <a [routerLink]="['/licitacion/id:'+item.id]">
            <div class="card-licitacion" >
                <!-- <span class="fecha" *ngIf="item.fecha_inicio_format != null"><strong style="color: #2f3848;">Inicio:</strong> {{item.fecha_inicio_format}}</span><br/> -->
                <span class="fecha hidden-xs" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</span>
                <span class="fecha hidden-xs" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</span>
                <div class="titulo"><b class="text-primary">Exp: {{item.num_expediente}}</b></div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</div>
                <div class="desc">{{item.objeto_contrato}}</div>
                <div class="footer" style="margin-top: 8px;">
                    <span style="font-weight: 600; font-size: 11px;" class="ubicacion"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.poblacion}}, {{item.provincia}}</span>
                    <strong style="float: right;">{{item.presupuesto_base_sinimpuestos_format}}€</strong>
                    <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado en-plazo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado anuncio-previo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado pendiente-de-adjudicacion">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado adjudicada">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado resuelta">{{item.estado}}</span>
                </div>
            </div>
        </a>
    </div>
</div>
<div class="dataTables_paginate paging_simple_numbers m-r-10" *ngIf="params && params.total">
    <ul class="pagination justify-content-end">
      <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}" *ngIf="params.page_index > 1">
        <a (click)="Paging('prev')" class="page-link">Anterior</a>
      </li>
      <li class="paginate_button page-item" *ngIf="params.page_index > 1">
        <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
      </li>
      <li class="paginate_button page-item active">
        <a class="page-link cursor">{{params.page_index}}</a>
      </li>
      <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
        <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
      </li>
      <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}" *ngIf="params.page_index < params.pages">
        <a (click)="Paging('next')" class="page-link">Siguiente</a>
      </li>
    </ul>
</div>
<h3 class="sin-resultados" *ngIf="!licitaciones || !licitaciones.length">
    Sin resultados. Pruebe a introducir una búsqueda diferente<br>
    <i class="fa fa-exclamation-triangle fa-2x text-warning"></i>
</h3>