import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let XLSX: any;
declare let saveAs: any;
declare let ace: any;
declare let tinymce: any;
declare let toastr: any;

@Component({
  selector: 'app-config-app',
  templateUrl: './config-app.component.html'
})
export class ConfigAppComponent implements OnInit {
  public data:any = {
    customcss: '',
    emailconf_host: '',
    emailconf_port: '',
    emailconf_ssl: 0,
    emailconf_email: '',
    emailconf_pass: '',
    emailconf_alias: '',
    emailconf_emailadmin_alias: '',
    emailconf_emailadmin1: '',
    emailconf_emailadmin2: '',
    emailconf_emailadmin3: '',
  };
  public lastrurl:string = '';
  public logs:string = '';
  public lastindeximportacion:number = 0;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(1);
    $.checkrolredirect(2);
    $.checkrolredirect(3);
    $.checkrolredirect(4);
    this.data = this.globals.config;
    if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.lastrurl = this.globals.GetData('last-url-importacion');
    this.lastindeximportacion = this.globals.GetData('last-index-importacion');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5
    });
    this.globals.Api('/configuracion').subscribe(data => {
      $.LoadingOverlay("hide");
      if (!data || data.error) return;
      this.globals.config = data;
      this.data = this.globals.config;
      if (this.data.customcss) this.data.customcss = this.data.customcss.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.appref.tick();
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    }, error => {
      // toastr.error(`No se ha podido cargar datos de configuración por falta de conexión`, 'Error!');
      $.LoadingOverlay("hide");
      $.csseditor = ace.edit("txtcustomcss");
      $.csseditor.setTheme("ace/theme/monokai");
      $.csseditor.session.setMode("ace/mode/css");
    });
    // this.globals.Api('/alo-forma-pago').subscribe(data => {
    //   if (!data || !data.length) return;
    //   this.data.formas_pago = data;
    // });
    if (!this.data.emailconf_ssl) this.data.emailconf_ssl = 0;
    $.logseditor = ace.edit("txtlogsdb");
    $.logseditor.setTheme("ace/theme/monokai");
    this.globals.Api('/logs-db').subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      $.logseditor.setValue(data.content);
    });
  }
  
  Guardar() {
    if ($.csseditor) this.data.customcss = $.csseditor.getValue();
    this.data.customcss = this.data.customcss.replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]*>?/gm, '');
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/guardar-configuracion', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
      this.globals.config = this.data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      if ($.CargarConfiguracionGlobal) $.CargarConfiguracionGlobal();
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

  EmailTest() {
    this.globals.Api('/email-test').subscribe(data => {
      swal('Enviado', 'Intento de email enviado al servidor', 'info');
    }, error =>  {
      swal('Error', 'Se ha producido un error', 'error');
    });
  }
  ExportarDB() {
    this.globals.Api('/exportar-db', {}).subscribe(data => {
      if (!data || !data.content || data.content == '') return;
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     'data:text/plain;charset=utf-8,'+data.content);
      downloadAnchorNode.setAttribute("download", "database.sql");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }
  BorrarLogs() {
    this.globals.Api('/borrar-logs-db').subscribe(data => {
      $.logseditor.setValue('');
    });
  }

  ResetURL() {
    this.lastrurl = '';
    this.globals.SaveData('', 'last-url-importacion');
    localStorage.removeItem('last-step');
  }
  ResetIndex() {
    this.lastindeximportacion = 0;
    this.globals.SaveData(0, 'last-index-importacion');
  }
  Importar() {
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.2)',
    //   imageResizeFactor: 0.5,
    //   text: 'Importando licitaciones ...'
    // });
    // this.globals.Api('/importar-licitaciones').subscribe(data => {
    //   $.LoadingOverlay("hide", true);
    //   if (!data || data.error) {
    //     swal('Error', 'Se ha producido un error', 'error');return;
    //   }
    //   this.Buscar();

    // }, error => {
    //   $.LoadingOverlay("hide", true);
    // });
    
    let errorImportacion = () => {
      $('#progress-importacion-datos').css('width', '0%');
      $('.progress').hide();
      $.buttoncancelloading('btnimportacion');
      toastr.error('Se ha producido un error importando datos', 'Error!');
      $.importandolicitaciones = false;
    };
    
    $.buttonloading('btnimportacion', 'Importando');
    $('#progress-importacion-datos').css('width', '0%');
    $('.progress').show();
    let step = 0;
    let stepPorcent = 100/(182*4);
    $.importandolicitaciones = true;
    this.logs = '';

    let ImportAnteriores = (num:number, final:boolean, callback:any) => {
      this.globals.Api('/importar-licitaciones-anteriores/FASJ98-3432QWEWQd'+(num ? '/'+num : '')).subscribe(data => {
        if (!data || data.error) {
          errorImportacion();return;
        }
        if (final) {
          $.importandolicitaciones = false;
          $('#progress-importacion-datos').css('width', '100%');
          $.buttoncancelloading('btnimportacion');
          setTimeout(() => {
            $('#progress-importacion-datos').css('width', '0%');
            $('.progress').hide();
          }, 1000);
          toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
          return;
        }
        step++;
        $('#progress-importacion-datos').css('width', (stepPorcent*step)+'%');
        if (callback) callback();
      }, error => {
        errorImportacion();
      });
    };
    
    let lasturl = null;
    let ImportarLicitaciones = (url:string = null) => {
      $.buttonloading('btnimportacion', 'Importando');
      $('.progress').show();
      if (this.lastrurl) {
        url = this.lastrurl;
        if (this.globals.GetData('last-step')) {
          step = this.globals.GetData('last-step');
          $('#progress-importacion-datos').css('width', (stepPorcent*step)+'%');
        }
      }
      this.globals.Api('/importar-licitaciones/FASJ98-3432QWEWQd', {url: url}).subscribe(data => {
        if (!data || data.error) {
          errorImportacion();return;
        }
        if (data.logs && data.logs.length) {
          for (let log of data.logs) {
            this.logs += ' *** ' + log + '<br>';
          }
        }
        // console.log(data.fecha);
        if (data.url && data.url != '' && data.url != lasturl) {
          lasturl = data.url;
          this.lastrurl = lasturl;
          this.globals.SaveData(this.lastrurl, 'last-url-importacion');
          step++;
          $('#progress-importacion-datos').css('width', (stepPorcent*step)+'%');
          this.globals.SaveData(step, 'last-step');
          ImportarLicitaciones(data.url);return;
        }
        this.lastrurl = '';
        this.globals.SaveData('', 'last-url-importacion');
        $.importandolicitaciones = false;
        $('#progress-importacion-datos').css('width', '100%');
        $('#btnimportacion').html(`<i class="fa fa-download"></i> Importar`);
        $('#btnimportacion').removeAttr('disabled');
        $('#btnimportacion').css('pointer-events', 'all');
        $('#btnimportacion').css('cursor', 'pointer');
        setTimeout(() => {
          $('#progress-importacion-datos').css('width', '0%');
          $('.progress').hide();
        }, 1000);
        toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
      }, error => {
        errorImportacion();
      });
    };
    ImportarLicitaciones();
  }
  ImportarOrigenDatos() {
    let errorImportacion = () => {
      $('#progress-importacion-origendatos').css('width', '0%');
      $('.progress-origen').hide();
      $.buttoncancelloading('btnimportacion-origendatos');
      toastr.error('Se ha producido un error importando datos', 'Error!');
    };
    
    $.buttonloading('btnimportacion-origendatos', 'Importando');
    $('#progress-importacion-origendatos').css('width', '0%');
    $('.progress-origen').show();
    let step = 1;
    let stepPorcent = 200;
    let total = 0;
    let totalpaginas = 0;
    this.logs = '';
    
    let lastindeximportacion = null;
    let ImportarLicitaciones = () => {
      $.buttonloading('btnimportacion-origendatos', 'Importando');
      $('.progress-origen').show();
      if (this.lastindeximportacion) {
        step = this.lastindeximportacion;
        $('#progress-importacion-origendatos').css('width', ( (stepPorcent * step) / total * 100 )+'%');
      }
      this.globals.Api('/importar-licitaciones-desde-origendatos', {page_index: step, page_size: stepPorcent}).subscribe(data => {
        if (!data || data.error) {
          errorImportacion();return;
        }
        if (data.logs && data.logs.length) {
          for (let log of data.logs) {
            this.logs += ' *** ' + log + '<br>';
          }
        }
        if (step <= totalpaginas) {
          $('#progress-importacion-origendatos').css('width', ( (stepPorcent * step) / total * 100 )+'%');
          step++;
          this.lastindeximportacion = step;
          this.globals.SaveData(this.lastindeximportacion, 'last-index-importacion');
          ImportarLicitaciones();
          return;
        }
        $('#progress-importacion-origendatos').css('width', '100%');
        $('#btnimportacion-origendatos').html(`<i class="fa fa-download"></i> Importar`);
        $('#btnimportacion-origendatos').removeAttr('disabled');
        $('#btnimportacion-origendatos').css('pointer-events', 'all');
        $('#btnimportacion-origendatos').css('cursor', 'pointer');
        setTimeout(() => {
          $('#progress-importacion-origendatos').css('width', '0%');
          $('.progress-origen').hide();
        }, 1000);
        toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
      }, error => {
        errorImportacion();
      });
    };
    this.globals.Api('/importar-licitaciones-desde-origendatos', {total: true}).subscribe(data => {
      console.log(data);
      if (!data || !data.total) return;
      total = data.total;
      totalpaginas = data.total / stepPorcent;
      ImportarLicitaciones();
    });
  }
}
