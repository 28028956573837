<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <th style="width:50px"></th>
                              <th order="organo_contratacion">Organismo</th>
                              <th order="localidad" style="width:200px">Localidad</th>
                              <th order="email" style="width:190px">Email</th>
                              <th style="width:50px">Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of empresas" class="cursor" (click)="VerLicitaciones(item)">
                              <td class="text-center">
                                <img *ngIf="item.imagen" [src]="item.imagen" class="img-usuario img-circle square">
                                <i *ngIf="!item.imagen || item.imagen == ''" class="icofont-bag-alt fa-2x"></i>
                              </td>
                              <td class="">
                                <div>
                                  <span class="font-bold text-primary">{{item.organo_contratacion}}</span>&nbsp;
                                  <span>({{item.total}} lic.)</span>
                                </div>
                              </td>
                              <td>{{item.localidad}}</td>
                              <td class="breaktd"><span *ngIf="item.email" style="word-break:break-all;"><i class="fa fa-envelope"></i> {{item.email}}</span></td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar($event, item)"><i class="fa fa-pencil text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-edit-organismo" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="max-width: 950px;">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body p-t-0">
          <div class="row">
            <div class="col-md-4 col-xs-12">
              <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                <i class="icofont-camera-alt font-50"></i>
              </div>
              <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
              </div>
            </div>
            <div class="col-md-8 col-xs-12">
              <div class="row">
                <div class="col-md-8 col-xs-12">
                  <div class="form-group">
                    <label class="text-primary">Nombre del organismo</label>
                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80" readonly>
                  </div>
                </div>
                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label>NIF / CIF</label>
                    <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 col-xs-12">
                  <div class="form-group">
                    <label>Direcci&oacute;n</label>
                    <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                  </div>
                </div>
                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label>C&oacute;digo postal</label>
                    <input type="number" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                      (change)="DatosLocalidad()">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 col-xs-12">
                  <div class="form-group">
                    <label>Localidad</label>
                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                  </div>
                </div>
                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label>País</label>
                    <input type="text" class="form-control" [(ngModel)]="data.pais" maxlength="50">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="form-group">
                <label>Email de contacto</label>
                <input type="text" class="form-control" [(ngModel)]="data.email" maxlength="80">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="form-group">
                <label>URL</label>
                <input type="url" class="form-control" [(ngModel)]="data.url" maxlength="400">
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btn-guardar" type="button" class="btn btn-primary" (click)="Guardar()">Guardar</button>
        </div>
      </div>
  </div>
</div>