<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content" *ngIf="data">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}
                                    <span *ngIf="GetEstoyEditando()" class="text-warning font-bold m-l-10 font-14">* Editando</span>
                                </h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <ng-container *ngIf="!adjudicaciones">
                                  <li><a [routerLink]="['/licitaciones']">Licitaciones</a></li>
                                </ng-container>
                                <ng-container *ngIf="adjudicaciones">
                                  <li><a [routerLink]="['/adjudicaciones']">Adjudicaciones</a></li>
                                </ng-container>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                
                <div class="row">
                    <div class="col-md-7 col-sm-12">
                        <ul class="list-group">
                            <li class="list-group-item active">Datos generales</li>
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Órgano de contratación <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" [(ngModel)]="data.organo_contratacion" maxlength="200">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('organo_contratacion')" (click)="BloquearDesbloquear('organo_contratacion')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('organo_contratacion')" (click)="BloquearDesbloquear('organo_contratacion')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Estado <span class="text-danger">*</span></label>
                                            <select class="form-control font-bold text-secondary" [(ngModel)]="data.estado">
                                                <option *ngFor="let item of estados" [value]="item.estado">{{item.estado}}</option>
                                              </select>
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('estado')" (click)="BloquearDesbloquear('estado')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('estado')" (click)="BloquearDesbloquear('estado')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Objeto de contrato <span class="text-danger">*</span></label>
                                            <textarea class="form-control" rows="3" [(ngModel)]="data.objeto_contrato" maxlength="2000"></textarea>
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('objeto_contrato')" (click)="BloquearDesbloquear('objeto_contrato')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('objeto_contrato')" (click)="BloquearDesbloquear('objeto_contrato')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Presupuesto base sin impuestos <span class="text-danger">*</span></label>
                                            <input type="number" class="form-control" [(ngModel)]="data.presupuesto_base_sinimpuestos" maxlength="40">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('presupuesto_base_sinimpuestos')" (click)="BloquearDesbloquear('presupuesto_base_sinimpuestos')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('presupuesto_base_sinimpuestos')" (click)="BloquearDesbloquear('presupuesto_base_sinimpuestos')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Valor estimado del contrato <span class="text-danger">*</span></label>
                                            <input type="number" class="form-control" [(ngModel)]="data.presupuesto_base_conimpuestos" maxlength="40">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('presupuesto_base_conimpuestos')" (click)="BloquearDesbloquear('presupuesto_base_conimpuestos')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('presupuesto_base_conimpuestos')" (click)="BloquearDesbloquear('presupuesto_base_conimpuestos')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Código CPV</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.cpv" maxlength="20">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('cpv')" (click)="BloquearDesbloquear('cpv')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('cpv')" (click)="BloquearDesbloquear('cpv')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Calle</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.calle" maxlength="200">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('calle')" (click)="BloquearDesbloquear('calle')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('calle')" (click)="BloquearDesbloquear('calle')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Código Postal</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="200">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('codpostal')" (click)="BloquearDesbloquear('codpostal')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('codpostal')" (click)="BloquearDesbloquear('codpostal')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Población <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" [(ngModel)]="data.poblacion" maxlength="200">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('poblacion')" (click)="BloquearDesbloquear('poblacion')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('poblacion')" (click)="BloquearDesbloquear('poblacion')"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12">
                                        <div class="form-group form-bloqueo">
                                            <label>Provincia <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="200">
                                            <i class="fa fa-unlock" *ngIf="!GetBloqueado('provincia')" (click)="BloquearDesbloquear('provincia')"></i>
                                            <i class="fa fa-lock" *ngIf="GetBloqueado('provincia')" (click)="BloquearDesbloquear('provincia')"></i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <ul class="list-group">
                            <li class="list-group-item active"><i class="icofont-file-pdf"></i> Documentación</li>
                            <li class="list-group-item">
                                <div class="no-resultados-2" *ngIf="!data.pliego_prescripciones_tecnicas && !data.pliego_clausulas_administrativas && !data.anexos_pliegos">
                                    Esta licitación no tiene documentos
                                </div>
                                <table class="table table-bordered table-vcenter table-licitacion">
                                    <tr *ngIf="data.pliego_prescripciones_tecnicas">
                                        <td class="table-licitacion-bg">Pliego prescripciones técnicas</td>
                                        <td>
                                            <a [href]="data.pliego_prescripciones_tecnicas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego prescripciones técnicas.pdf</a>
                                            <!-- <a class="btn btn-xs pull-right" (click)="DelDocumento('pliego_prescripciones_tecnicas')"><i class="fa fa-trash"></i></a> -->
                                        </td>
                                    </tr>
                                    <tr *ngIf="data.pliego_clausulas_administrativas">
                                        <td class="table-licitacion-bg">Pliego cláusulas administrativas</td>
                                        <td>
                                            <a [href]="data.pliego_clausulas_administrativas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego cláusulas administrativas.pdf</a>
                                            <!-- <a class="btn btn-xs pull-right" (click)="DelDocumento('pliego_clausulas_administrativas')"><i class="fa fa-trash"></i></a> -->
                                        </td>
                                    </tr>
                                    <tr *ngIf="data.anexos_pliegos">
                                        <td class="table-licitacion-bg">Anexos pliegos</td>
                                        <td>
                                            <a [href]="data.anexos_pliegos" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{data.id_anexos_pliegos}}</a>
                                            <!-- <a class="btn btn-xs pull-right" (click)="DelDocumento('anexos_pliegos')"><i class="fa fa-trash"></i></a> -->
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="data.documentos && data.documentos.length">
                                        <tr *ngFor="let item of data.documentos">
                                            <td class="table-licitacion-bg">Documento adjuntado</td>
                                            <td>
                                                <a [href]="item.url" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{item.nombre}}</a>
                                                <a class="btn btn-xs pull-right" (click)="DelDocumentoAdjuntado(item)"><i class="fa fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>
                                <div class="m-t-10" *ngFor="let item of data.documentos_add">
                                    <h4 class="font-14">Añadir estos documentos</h4>
                                    <table class="table table-bordered table-vcenter table-licitacion">
                                        <tr *ngFor="let item of data.documentos_add">
                                            <td class="table-licitacion-bg">
                                                <input type="text" class="form-control" [(ngModel)]="item.titulo" maxlength="50" placeholder="Título del documento">
                                            </td>
                                            <td>
                                                <a target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{item.nombre}}{{item.extension}}</a>
                                                <a class="btn btn-xs pull-right" (click)="DelDocumentoNuevo(item)"><i class="fa fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="text-center m-t-20">
                                    <hr>
                                    <a class="btn btn-success" (click)="AddDocumento()">Añadir documento <i class="fa fa-plus"></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <ul class="list-group">
                            <li class="list-group-item active">Lotes</li>
                            <li class="list-group-item">
                                <div class="no-resultados-2" *ngIf="!data.lotes || !data.lotes.length">
                                    Esta licitación no tiene lotes
                                </div>
                                <ng-container *ngFor="let item of data.lotes">
                                    <div class="relative">
                                        <table class="table table-bordered table-vcenter table-licitacion">
                                            <tr><td class="table-licitacion-bg">Resultado</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.resultado" maxlength="50"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Lote</td>
                                                <td>
                                                    <textarea class="form-control" [(ngModel)]="item.objeto_contrato" rows="2" maxlength="500"></textarea>
                                                </td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Número de lote</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.num_lote" maxlength="50"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Adjudicatario</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.lote_adjudicatario" maxlength="2000"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Nº de Licitadores Presentados</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.num_licitadores_presentados" maxlength="10"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Importe de adjudicación sin impuestos</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.lote_importe_sinimpuestos" maxlength="20"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Importe de adjudicación con impuestos</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.lote_importe_conimpuestos" maxlength="20"></td>
                                            </tr>
                                            <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                                            <tr><td class="table-licitacion-bg">Lugar de ejecución</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.lote_lugar_ejecucion" maxlength="1000"></td>
                                            </tr>
                                        </table>
                                        <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;" (click)="DelLote(item)">Borrar lote <i class="fa fa-trash"></i></a>
                                    </div>
                                </ng-container>
                                <div class="text-center m-t-20">
                                    <hr>
                                    <a class="btn btn-success" (click)="AddLote()">Añadir lote <i class="fa fa-plus"></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <ul class="list-group">
                            <li class="list-group-item active">Ganadores</li>
                            <li class="list-group-item">
                                <div class="no-resultados-2" *ngIf="!data.ganador || !data.ganador.length">
                                    Esta licitación no tiene ganadores
                                </div>
                                <ng-container *ngFor="let item of data.ganador">
                                    <div class="relative">
                                        <table class="table table-bordered table-vcenter table-licitacion">
                                            <tr><td class="table-licitacion-bg">Resultado</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.resultado" maxlength="50"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Número de lote</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.num_lote" maxlength="50"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Número de contrato</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.num_contrato" maxlength="50"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Adjudicatario</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.lote_adjudicatario" maxlength="2000"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Nº de Licitadores Presentados</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.num_licitadores_presentados" maxlength="10"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Importe de adjudicación sin impuestos</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.lote_importe_sinimpuestos" maxlength="20"></td>
                                            </tr>
                                            <tr><td class="table-licitacion-bg">Importe de adjudicación con impuestos</td>
                                                <td><input type="number" class="form-control form-control-num" [(ngModel)]="item.lote_importe_conimpuestos" maxlength="20"></td>
                                            </tr>
                                            <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                                            <tr><td class="table-licitacion-bg">Lugar de ejecución</td>
                                                <td><input type="text" class="form-control" [(ngModel)]="item.lote_lugar_ejecucion" maxlength="1000"></td>
                                            </tr>
                                        </table>
                                        <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;" (click)="DelGanador(item)">Borrar ganador <i class="fa fa-trash"></i></a>
                                    </div>
                                </ng-container>
                                <div class="text-center m-t-20">
                                    <hr>
                                    <a class="btn btn-success" (click)="AddGanador()">Añadir ganador <i class="fa fa-plus"></i></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>