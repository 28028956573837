<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-header></app-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="container p-t-20 p-b-20">
                    <!-- <h1 class="logo-home">
                        <img src="assets/logo-isotipo.svg"> <strong>LicitaPYME</strong>
                    </h1> -->
                    <div class="row">
                        <div class="col-md-9 col-sm-12">
                            <h1><b class="text-primary">{{adjudicatario.lote_adjudicatario}}</b></h1>
                            <p *ngIf="seguido == false" class="btn-seguir-organismo" (click)="SeguirAdjudicatario()"><i class="icofont-ui-love"></i> Seguir</p>
                            <p *ngIf="seguido == true" class="btn-seguir-organismo" (click)="DeleteLicitacionSeguida()"style="color: white"><i class="icofont-ui-love" style="color: #e34d63"></i> Siguiendo</p>
                        </div>
                        <div class="col-md-3 col-sm-12 datos-estadisticas text-center">
                            <img *ngIf="adjudicatario && adjudicatario.imagen" src="{{adjudicatario.imagen}}" style="width: 300px; float: right"/>
                            <img *ngIf="adjudicatario && !adjudicatario.imagen" src="assets/imagen-defecto-empresa.png" style="width: 150px; float: right"/>
                        </div>
                    </div>
                    <!-- Fila 1-->
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div>
                                <!-- <p class="name-licitacion">{{adjudicatario.nombre}}</p> -->
                                <h2 class="text-primary">Datos generales</h2>
                                <p class="no-margin-bottom"><b class="titleData">Nombre del adjudicatario: </b><span>{{adjudicatario.nombre}}</span></p>
                                <p class="no-margin-bottom" *ngIf="adjudicatario.direccion"><b class="titleData">Dirección: </b><span>{{adjudicatario.direccion}}, {{adjudicatario.codpostal}}, {{adjudicatario.localidad}}</span></p>
                                <p class="no-margin-bottom" *ngIf="adjudicatario.nifcif"><b class="titleData">NIF/CIF: </b><span>{{adjudicatario.nifcif}}</span></p>
                                <p class="no-margin-bottom" *ngIf="adjudicatario.pais"><b class="titleData">País: </b><span>{{adjudicatario.pais}}</span></p>
                                <p class="no-margin-bottom" *ngIf="adjudicatario.email"><b class="titleData">Email: </b><span>{{adjudicatario.email}}</span></p>
                                <a *ngIf="adjudicatario.url" href="{{adjudicatario.url}}" target="_blank">Acceder a la plataforma de contratación:</a><br/>
                                <a *ngIf="adjudicatario.url" href="{{adjudicatario.url}}" target="_blank">{{adjudicatario.url}}</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 p-t-20 datos-right">
                            <div class="font-18 m-b-30"><span class="text-primary m-r-15">Baja:</span> <b>{{adjudicatario.baja}}%</b></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <h2 class="text-primary">Adjudicaciones <span class="text-secondary font-12" *ngIf="adjudicaciones && adjudicaciones.length">({{adjudicaciones.length}})</span></h2>
                            <div *ngIf="!adjudicaciones || !adjudicaciones.length" class="text-center p-t-20 p-b-20">
                                <div class="no-resultados"><i class="fa fa-exclamation-circle"></i> Aún no tiene adjudicaciones</div>
                            </div>
                            <div id="content-licitaciones-cards" *ngIf="adjudicaciones.length > 0">
                                <div  *ngFor="let item of adjudicaciones">
                                    <a [routerLink]="['/licitacion/id:'+item.id]">
                                        <div class="card-licitacion" >
                                            <span class="fecha hidden-xs" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</span>
                                            <span class="fecha hidden-xs" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</span>
                                            <div class="titulo"><b class="text-primary">Exp: {{item.num_expediente}}</b></div>
                                            <div class="fecha desktop-hidden" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</div>
                                            <div class="fecha desktop-hidden" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</div>
                                            <div class="desc">{{item.objeto_contrato}}</div>
                                            <div class="footer" style="margin-top: 8px;">
                                                <span style="font-weight: 600; font-size: 11px;" class="ubicacion"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.poblacion}}, {{item.provincia}}</span>
                                                <strong style="float: right;">{{item.presupuesto_base_sinimpuestos_format}}€</strong>
                                                <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado en-plazo">{{item.estado}}</span>
                                                <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado anuncio-previo">{{item.estado}}</span>
                                                <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado pendiente-de-adjudicacion">{{item.estado}}</span>
                                                <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado adjudicada">{{item.estado}}</span>
                                                <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado resuelta">{{item.estado}}</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
        <app-footer></app-footer>
    </div>
    <!-- END Page Container -->
</div>