import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;

@Component({
  selector: 'app-mis-obras',
  templateUrl: './mis-obras.component.html'
})
export class MisObrasComponent implements OnInit {

  public licitaciones:any = [];
  public organismos:any = [];
  public adjudicatarios:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    App.init();
    this.CargarMisLicitacionesId();
    this.CargarOrganismosSeguidos();
    this.CargarAdjudicatariosSeguidos();
  }

  CargarMisLicitacionesId(){
    //let dataUser = JSON.parse(localStorage.getItem('userdata'));
    //this.globals.Api('/licitaciones-seguidas', {id_usuario: dataUser.id}).subscribe(data => {
    this.globals.Api('/licitaciones-seguidas-id').subscribe(data => {
      if (!data) return ;
      this.CargarMisLicitaciones(data);
    });
  }
  CargarMisLicitaciones(data:any){
    this.globals.Api('/licitaciones-seguidas',{licitacionesId: data}).subscribe(data => {
      if (!data) return ;
      this.licitaciones = data;
      this.globals.SaveData(data, 'mis-licitaciones');
    });
  }
  DeleteOrganizacionSeguida(licitacion:any){
    swal({
      title: "¿Dejar de seguir licitación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-seguidas-del', {id: licitacion.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarMisLicitacionesId();
        this.appref.tick();
      });
    });
  }
  DeleteEmpresaSeguida(licitacion:any){
    swal({
      title: "¿Dejar de seguir licitación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-seguidas-del', {id: licitacion.id}).subscribe(data => {
        console.log(data);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarMisLicitacionesId();
        this.appref.tick();
      });
    });
  }
  CargarOrganismosSeguidos(){
    this.globals.Api('/organismos-seguidos').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.organismos = data;
    });
  }
  DeleteOrganismoSeguido(organismo:any){
    swal({
      title: "¿Dejar de seguir organismo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/organismo-seguidas-del', {id: organismo.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarOrganismosSeguidos();
        this.appref.tick();
      });
    });
  }
  CargarAdjudicatariosSeguidos(){
    this.globals.Api('/adjudicatarios-seguidos').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.adjudicatarios = data;
    });
  }
  DeleteAdjudicatarioSeguido(adjudicatario:any){
    swal({
      title: "¿Dejar de seguir esta empresa?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/adjudicatario-seguidos-del', {nombre_adjudicatario: adjudicatario.lote_adjudicatario}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarAdjudicatariosSeguidos();
        this.appref.tick();
      });
    });
  }
}
