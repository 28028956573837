import { ApplicationRef, Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { BuscadorComponent } from 'src/app/frontend/controls/buscador/buscador.component';
import { BuscadorNuevoComponent } from '../controls/buscador-nuevo/buscador-nuevo.component';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  public busqueda:string = '';
  public licitaciones:any = [];
  public values = {};
  public total_pordeterminar = 0;
  @ViewChild(BuscadorNuevoComponent) buscadorComponent: BuscadorNuevoComponent;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    this.CargarLicitaciones();
    $.CargarMapa = () => {
      this.CargarMapa();
    };
  }
  ColoresDefinidos() {
    return [
      '#E34D63',
      '#041E4B',
      // '#519662',
      // '#DE815C',
    ];
  }
  CssColors() {
    var rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
    var allColors = [];
    var elems = document.getElementsByTagName('*');
    var total = elems.length;

    var x,y,elemStyles,styleName,styleValue,rgbVal;
    for(x = 0; x < total; x++) {
        elemStyles = window.getComputedStyle(elems[x]);
        for (y = 0; y < elemStyles.length; y++) {
            styleName = elemStyles[y];
            styleValue = elemStyles[styleName];
            if (!styleValue) continue;
            styleValue += "";
            rgbVal = styleValue.match(rgbRegex);
            if (!rgbVal) continue;
            if (allColors.indexOf(rgbVal.input) == -1) {
              if (rgbVal.input.indexOf('rgb(255, 255, 255)') != -1) continue;
              if (rgbVal.input.indexOf('rgba(255, 255, 255') != -1) continue;
              if (rgbVal.input.indexOf('rgb(240, 240, 240)') != -1) continue;
              if (rgbVal.input.indexOf('rgba(0, 0, 0') != -1) continue;
              if (rgbVal[1] > 150 && rgbVal[2] > 150 && rgbVal[3] > 150) continue;
              allColors.push(rgbVal.input);
            }
        }
    }
    return allColors;
  }
  RandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  CargarLicitaciones() {
    // if (this.globals.GetData('ultimas-licitaciones')) this.licitaciones = this.globals.GetData('ultimas-licitaciones');
    // this.globals.Api('/licitaciones', {page_index: 1, page_size: 5, estado: 'EN PLAZO (PUB)'}).subscribe(data => {
    //   if (!data || !data.length) return;
    //   this.licitaciones = data;
    //   this.globals.SaveData(data, 'ultimas-licitaciones');
    // });
    this.globals.Api('/licitaciones-mapa', {tipo: 'provincias-nacional'}).subscribe(data => {
      if (!data) return;
      let colores = this.ColoresDefinidos();
      this.values = {};
      for (let item of data) {
        if (!item.codigo) continue;
        let random = Math.floor(Math.random() * colores.length);
        this.values[item.codigo] = colores[random];
        // this.values[item.codigo] = '#DDD';
        this.values[item.codigo+'-value'] = item.total;
        this.values[item.codigo+'-provincia'] = item.provincia;
      }
      this.globals.SaveData(this.values, 'licitaciones-provincias');
      this.CargarMapa();
    });
  }
  CargarMapa() {
    $('.jvectormap-tip').remove();

    let localidades = [];
    let map,
        markers = [],
        cityAreaData = [];
    let that = this;
    let container = $('.map-container');
    container.html('<div id="mapa-localidades" style="width:100%;height:62vh"></div>');
    
    let mapa = $('#mapa-localidades').vectorMap({
      container: container,
      map: 'es_merc',
      // zoomOnScroll: false,
      // panOnDrag: false,
      focusOn: {
        // lat: 39.2,
        // lng: -6.155078983139426,
        // scale: 6,
        lat: 40.42156491202462,
        lng: -2.6027548019823152,
        scale: 1.8,
        animate: true
      },
      backgroundColor: "transparent",
      regionStyle: {
        initial: {
            fill: '#e4e4e4',
            "fill-opacity": 0.9,
            stroke: 'none',
            "stroke-width": 0,
            "stroke-opacity": 0
        }
      },
      regionLabelStyle: {
        initial: {
          fill: 'white'
        },
        hover: {
          fill: 'black'
        }
      },
      series: {
        regions: [{
          attribute: 'fill',
          values: null,
        }]
      },
      labels: {
        regions: {
          render: code => {
            if (!that.values[code+'-value']) return;
            let label = '';
            label = that.values[code+'-value'];
            return label;
          }
        }
      },
      onRegionTipShow: (event, label, code)=>{
        if (!this.values[code+'-value']) return;
      },
      onRegionClick: function (event, code) {
        if (!that.values[code+'-value']) return;
        that.CargarMapaLocalidades(code);
        that.buscadorComponent.FiltrarProvincia(code, that.values[code+'-provincia'], 'En plazo (PUB)', '', false);
      }
    });
    let mapObject = $('#mapa-localidades').vectorMap('get', 'mapObject');
    if (this.globals.GetData('licitaciones-provincias')) {
      this.values = this.globals.GetData('licitaciones-provincias');
      mapObject.series.regions[0].setValues(this.values);
    }
    /*
    this.globals.Api('/licitaciones-mapa', {tipo: 'provincias'}).subscribe(data => {
      if (!data) return;
      this.values = {
        'ES-CC': '#e34d63',
        'ES-BA': '#072861',
        'ES-CC-value': data.caceres,
        'ES-BA-value': data.badajoz,
        'total': data.total
      };
      this.globals.SaveData(this.values, 'licitaciones-provincias');
      // mapObject.series.regions[0].max = values['total'];
      mapObject.series.regions[0].setValues(this.values);
      var arrayLabels = $('text.jvectormap-region.jvectormap-element');
      // console.log(arrayLabels);
      // $(arrayLabels[0]).html($(arrayLabels[0]).text());
      // $(arrayLabels[1]).html($(arrayLabels[1]).text());
      //$('.jvectormap-region.jvectormap-element').html($('.jvectormap-region.jvectormap-element').text());
      $('svg text').each(function(index) {
        var text = $(this).text();
        var newtext = '';
        var num = 0;
        for (var str of text.split('-')) {
          newtext += `<tspan x="${$(this).attr('x')-20}" dy="1.2em" ${num == 0 ? 'style="font-size: 30px;"' : ''}>${str}</tspan>`;
          num++;
        }
        $(this).html(newtext);
      });
    });
    */
    this.globals.Api('/licitaciones-mapa', {tipo: 'provincias-nacional', pordeterminar_count: true}).subscribe(data => {
      if (!data) return;
      this.total_pordeterminar = data.total;
    });
  }
  CargarMapaLocalidades(codigo:string) {
    $('.jvectormap-tip').remove();
    $("#mapa-localidades").append('<div id="map-action">Cargando mapa ...</div>');
    this.globals.Api('/licitaciones-mapa', {tipo: 'localidades-nacional', provincia_codigo: codigo}).subscribe(data => {
      if (!data || !data.length) {
        $("#map-action").text('Atrás');
        $('#map-action').click(() => {
          this.CargarMapa();
          this.buscadorComponent.ClearAllFiltros();
        });
        return;
      }
      let markers = [];
      let cityAreaData = [];
      for (let item of data) {
        if (!item.coords) continue;
        if (!item.coords.lat) continue;
        markers.push({
          latLng: [item.coords.lat, item.coords.lon],
          name: item.localidad
        });
        cityAreaData.push(item.total);
      }

      let localidades = [];
      let map;
      let that = this;
      let container = $('.map-container');
      container.html('<div id="mapa-localidades" style="width:100%;height:62vh;"></div>');
      $("#mapa-localidades").append('<div id="map-action">Atrás</div>');
      $('#map-action').click(() => {
        this.CargarMapa();
        this.buscadorComponent.ClearAllFiltros();
      });
      let mapa = $('#mapa-localidades').vectorMap({
        container: container,
        map: 'es_merc',
        focusOn: {
          lat: data[0].coords.lat,
          lng: data[0].coords.lon,
          scale: 6,
          animate: true
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {fill: '#e34d63'},
          selected: {fill: '#e34d63'}
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html(
            '<b class="numero">'+cityAreaData[code]+'</b><br>'+
            '<span><br/>En plazo</span></br>'+
            '<span class="lugar">'+el.html()+'</span>'
          );
        },
        onMarkerClick: function (event, code) {
          that.buscadorComponent.AplicarFiltro(markers[code].name, 'En plazo (PUB)', '', false);
        }
      });
    });
    this.globals.Api('/licitaciones-mapa', {tipo: 'localidades-nacional', provincia_codigo: codigo, pordeterminar_count: true}).subscribe(data => {
      if (!data) return;
      this.total_pordeterminar = data.total;
    });
  }
  Buscar() {
  }

}
