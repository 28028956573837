<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                                <button id="btnimportacion" class="btn btn-xs btn-secondary middle m-l-10" (click)="Importar()"><i class="fa fa-download"></i> Importar</button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-7 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()" *ngIf="!adjudicaciones">
                            <i class="fa fa-plus"></i> Nueva licitación
                          </a>
                          <a class="btn btn-primary btn-block" (click)="Nuevo()" *ngIf="adjudicaciones">
                            <i class="fa fa-plus"></i> Nueva adjudicación
                          </a>
                        </div> -->
                    </div>
                </div>
                <div class="progress" style="width: 100%;display: none;">
                    <div id="progress-importacion-datos" class="progress-bar progress-bar-striped progress-bar-animated progress-bar-primary" style="width:0%" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <!-- Table Styles Content -->
                <div class="text-right">
                  <div class="btn-group btn-group-sm" role="group" aria-label="...">
                    <button type="button" class="btn btn-default" (click)="FiltrarEstado()">Todo</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('ADJUDICADA (ADJ)')">Adjudicadas</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('ANUNCIO PREVIO (PRE)')">Anuncio previo</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('EN PLAZO (PUB)')">En plazo</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('PENDIENTE DE ADJUDICACIÓN (EV)')">Pendiente</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('RESUELTA (RES)')">Resuelta</button>
                    <button type="button" class="btn btn-secondary" (click)="FiltrarEstado('ANULADO (ANUL)')">Anulado</button>
                  </div>
                </div>
                <div class="table-responsive table-grid block full break-padding">
                    <!--
                    Available Table Classes:
                        'table'             - basic table
                        'table-bordered'    - table with full borders
                        'table-borderless'  - table with no borders
                        'table-striped'     - striped table
                        'table-condensed'   - table with smaller top and bottom cell padding
                        'table-hover'       - rows highlighted on mouse hover
                        'table-vcenter'     - middle align content vertically
                    -->
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <th order="objeto_contrato">Título</th>
                              <th style="width:150px" order="fecha_actualizacion">Fecha act.</th>
                              <th style="width:150px" order="estado">Estado</th>
                              <th order="poblacion">Localidad</th>
                              <th order="provincia">Provincia</th>
                              <!-- <th order="valor_estimado">Valor estimado</th>
                              <th order="presupuesto_base_sinimpuestos">Pres. Sin Imp.</th> -->
                              <!-- <th order="presupuesto_base_conimpuestos">Pres. Con Imp.</th> -->
                              <th order="presupuesto_base_sinimpuestos">Pres. Sin Imp.</th>
                              <th style="width:50px">Visible</th>
                              <th style="width:50px">Editar</th>
                              <th style="width:50px">Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of licitaciones" class="cursor">
                              <td class="" (click)="VistaPrevia(item)">
                                <div class="text-ellipsis font-bold text-primary" style="width:230px" [title]="item.objeto_contrato">{{item.objeto_contrato}}</div>
                                <div>Exp: {{item.num_expediente}} <i class="icofont-history text-info font-18" *ngIf="item.cambios && item.cambios.length"></i></div>
                                <div *ngIf="item.lotes && item.lotes.length">{{item.lotes.length}} Lote(s)</div>
                              </td>
                              <td>{{item.fecha_actualizacion_format}}</td>
                              <td>
                                <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado-badge en-plazo">{{item.estado}}</span>
                                <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado-badge anuncio-previo">{{item.estado}}</span>
                                <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado-badge pendiente-de-adjudicacion">{{item.estado}}</span>
                                <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado-badge adjudicada">{{item.estado}}</span>
                                <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado-badge resuelta">{{item.estado}}</span>
                                <span *ngIf="item.estado == 'ANULADO (ANUL)'" class="estado-badge anulado">{{item.estado}}</span>
                              </td>
                              <td>{{item.poblacion}}</td>
                              <td>{{item.provincia}}</td>
                              <!-- <td class="text-primary text-center font-bold">
                                {{item.valor_estimado_format}} €
                              </td>
                              <td class="text-primary text-center font-bold">
                                {{item.presupuesto_base_sinimpuestos_format}} €
                              </td> -->
                              <td class="text-primary text-center font-bold breaktd">
                                {{item.presupuesto_base_sinimpuestos_format}} €
                              </td>
                              <td class="text-center cursor btntd" (click)="CambiarVisibilidad(item)">
                                <a *ngIf="item.visible"><i class="fa fa-check text-success"></i></a>
                                <a *ngIf="!item.visible"><i class="fa fa-close text-danger"></i></a>
                              </td>
                              <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>