<h3 class="m-t-0 m-b-20 inline-block" *ngIf="tipo == 'dashboard'">Encuentra licitaciones de Extremadura</h3>
<span (click)="MostrarMapa()" id="button-mostrar-mapa" class="m-l-10 m-b-20" style="background-color: #e34d63;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    vertical-align: top;
    display: none;">Mostrar mapa</span>
<div class="input-group width-100">
    <input id="filter-search" type="text" placeholder="Expediente, localidad, estado ..." class="form-control" 
    (focus)="MostrarComboBuscar()" autocomplete="off" 
    (keyup)="Buscar($event)" 
    (keyup.enter)="AutorrellenarTodosResutados()" [(ngModel)]="busqueda">
    <div class="input-group-append cursor" (click)="AutorrellenarTodosResutados()">
    <span class="input-group-addon" style="background: #072861;"><i class="fa fa-search" style="color: white;"></i></span>
    </div>
</div>
<div id="content-loading" style="display: none;" *ngIf="tipo != 'mis-obras'">
    <p><img src="assets/loading.gif" style="margin-right: 10px; width: 70px;">Cargando datos...</p>
</div>
<div *ngIf="
    EstadosLicitaciones.length 
    || LocalidadesLicitaciones.length 
    || NombresLicitaciones.length 
    || ExpedienteLicitaciones.length 
    || OrganismosLicitaciones.length 
    || AdjudicatariosLicitaciones.length" 
    id="content-search" style="display: block">
    <div *ngIf="NombresLicitaciones.length > 0">
        <div style="padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 11px;
            background: rgb(7, 40, 97);
            color: white;">Nombre licitaciones:</div>

        <div>
            <div class="hover-search" style="cursor: pointer; color: #e34d63; font-weight: 500;" (click)="AutorrellenarTodosResutados()">Ver los {{contLicitaciones}} resultados</div>
        </div>
        <div class="display-6-elements" *ngFor="let nombre of NombresLicitaciones">
            <a [routerLink]="['/licitacion/id:'+nombre[1]]">
                <div class="hover-search" [innerHTML]="nombre[0]" style="cursor: pointer;"></div>
            </a>
        </div>
    </div>
    <div *ngIf="LocalidadesLicitaciones.length > 0">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Localidad:</div>
        <div *ngFor="let localidad of LocalidadesLicitaciones">
            <div class="hover-search" style="cursor: pointer;" (click)="AplicarBusqueda(localidad,filtros.estado)">{{localidad}}</div>
        </div>
    </div>
    <div *ngIf="OrganismosLicitaciones.length > 0">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Organismos:</div>
        <div *ngFor="let organismo of OrganismosLicitaciones">
            <div class="hover-search" style="cursor: pointer;" (click)="AbrirOrganismo(organismo)">{{organismo}}</div>
        </div>
    </div>
    <div *ngIf="AdjudicatariosLicitaciones.length > 0">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Adjudicatarios:</div>
        <div *ngFor="let adjudicatario of AdjudicatariosLicitaciones">
            <div class="hover-search" style="cursor: pointer;" (click)="AbrirAdjudicatario(adjudicatario)">{{adjudicatario}}</div>
        </div>
    </div>
    <div *ngIf="EstadosLicitaciones.length > 0 && tipo != 'adjudicaciones'">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Estado:</div>
        <div *ngFor="let estado of EstadosLicitaciones">
            <div class="hover-search" style="cursor: pointer;" (click)="AplicarBusqueda(filtros.localidad,estado)">{{estado}}</div>
        </div>
    </div>
    <div *ngIf="ExpedienteLicitaciones.length > 0">
        <div style="padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 11px;
        background: rgb(7, 40, 97);
        color: white;">Expediente:</div>
        <div *ngFor="let expediente of ExpedienteLicitaciones">
            <a [routerLink]="['/licitacion/id:'+expediente[1]]">
                <div class="hover-search" style="cursor: pointer;">{{expediente[0]}}</div>
            </a>
        </div>
    </div>
</div>
<h3 id="title-content-licitaciones-cards" class="m-t-40" *ngIf="tipo == 'dashboard'">Licitaciones recientes</h3>
<h3 id="title-content-licitaciones-cards" class="m-t-40" *ngIf="tipo == 'mis-obras'"></h3>
<div *ngIf="filtros.localidad != '' || filtros.estado != '' || filtros.orden != '' || filtros.busqueda != ''" id="content-licitaciones-filters">
    <div *ngIf="filtros.localidad != ''" class="licitaciones-filters" (click)="ClearFiltroLocalidad()" style="margin-bottom: 5px;">X Localidad: {{filtros.localidad}}</div>
    <div *ngIf="filtros.estado != ''" class="licitaciones-filters" (click)="ClearFiltroEstado()" style="margin-bottom: 5px;">X Estado: {{filtros.estado}}</div>
    <div *ngIf="filtros.orden != ''" class="licitaciones-filters" (click)="ClearFiltroOrden()" style="margin-bottom: 5px;">X Orden: {{filtros.orden}}</div>
    <div *ngIf="filtros.busqueda != ''" class="licitaciones-filters" (click)="ClearFiltroBusqueda()" style="margin-bottom: 5px;">X Búsqueda: {{filtros.busqueda}}</div>
</div>
<div class="clearfix"></div>
<div class="content-buttons-ord-filt">
    <div style="float: left; margin-left: 0px; background: #072861; cursor: default">
        Mostrando {{licitaciones.length}} resultados:
    </div>
    <div (click)="MostrarFiltroEstado()" *ngIf="tipo != 'adjudicaciones'" class="relative">Filtrar por estado
        <div id="content-estados-selector" class="selector-filtros">
            <div (click)="AplicarBusqueda(filtros.localidad,'Anuncio previo (PRE)',filtros.orden)">Anuncio previo (PRE)</div>
            <div (click)="AplicarBusqueda(filtros.localidad,'En plazo (PUB)',filtros.orden)">En plazo (PUB)</div>
            <div (click)="AplicarBusqueda(filtros.localidad,'Pendiente de adjudicación (EV)',filtros.orden)">Pendiente de adjudicación (EV)</div>
            <div (click)="AplicarBusqueda(filtros.localidad,'Adjudicada (ADJ)',filtros.orden)">Adjudicada (ADJ)</div>
            <div (click)="AplicarBusqueda(filtros.localidad,'Resuelta (RES)',filtros.orden)">Resuelta (RES)</div>
        </div>
    </div>
    <div (click)="MostrarFiltroOrden()" class="relative">Ordenar por
        <div id="content-orden-selector" class="selector-filtros">
            <div (click)="AplicarBusqueda(filtros.localidad,filtros.estado,'Defecto')">Defecto</div>
            <div (click)="AplicarBusqueda(filtros.localidad,filtros.estado,'Nombre')">Nombre</div>
            <div (click)="AplicarBusqueda(filtros.localidad,filtros.estado,'Recientes')">Recientes</div>
            <div (click)="AplicarBusqueda(filtros.localidad,filtros.estado,'Cuantía ascendiente')">Importe ascendiente</div>
            <div (click)="AplicarBusqueda(filtros.localidad,filtros.estado,'Cuantía descendiente')">Importe descendiente</div>
        </div>
    </div>
    <div id="content-loading-2" style="display: none;">
        <p><img src="assets/loading.gif" style="margin-right: 10px; width: 70px;">Cargando datos...</p>
    </div>
</div>
<div class="clearfix"></div>
<div id="content-licitaciones-cards" *ngIf="tipo == 'dashboard'">
    <div  *ngFor="let item of licitaciones">
        <a [routerLink]="['/licitacion/id:'+item.id]">
            <div class="card-licitacion" >
                <!-- <span class="fecha" *ngIf="item.fecha_inicio_format != null"><strong style="color: #2f3848;">Inicio:</strong> {{item.fecha_inicio_format}}</span><br/> -->
                <span class="fecha hidden-xs" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</span>
                <span class="fecha hidden-xs" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</span>
                <div class="titulo"><b class="text-primary">Exp: {{item.num_expediente}}</b></div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</div>
                <div class="desc">{{item.objeto_contrato}}</div>
                <div class="footer" style="margin-top: 8px;">
                    <span style="font-weight: 600; font-size: 11px;" class="ubicacion"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.poblacion}}, {{item.provincia}}</span>
                    <strong style="float: right;">{{item.presupuesto_base_sinimpuestos_format}}€</strong>
                    <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado en-plazo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado anuncio-previo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado pendiente-de-adjudicacion">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado adjudicada">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado resuelta">{{item.estado}}</span>
                </div>
            </div>
        </a>
    </div>
</div>
<!--    Mis obras   -->
<div id="content-licitaciones-cards" *ngIf="tipo == 'mis-obras'" style="margin-top: 40px;">
    <div *ngIf="!licitaciones || !licitaciones.length" class="text-center p-t-20 p-b-20">
        <div class="no-resultados"><i class="fa fa-exclamation-circle"></i> Aún no sigues licitaciones</div>
    </div>
    <div *ngFor="let item of licitaciones">
        <div class="card-licitacion" >
            <!-- <span class="fecha" *ngIf="item.fecha_inicio_format != null"><strong style="color: #2f3848;">Inicio:</strong> {{item.fecha_inicio_format}}</span><br/> -->
            <a [routerLink]="['/licitacion/id:'+item.id]">
                <span class="fecha hidden-xs" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</span>
                <span class="fecha hidden-xs" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</span>
                <div class="titulo"><b class="text-primary">Exp: {{item.num_expediente}}</b></div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</div>
                <div class="desc">{{item.objeto_contrato}}</div>
            </a>
            <div class="footer" style="margin-top: 8px;"><a [routerLink]="['/licitacion/id:'+item.id]">
                <span style="font-weight: 600; font-size: 11px;" class="ubicacion"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.poblacion}}, {{item.provincia}}</span></a>
                <a class="unfollow-button hidden-xs" (click)="DeleteLicitacionSeguida(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                <a [routerLink]="['/licitacion/id:'+item.id]"><strong style="float: right;">{{item.presupuesto_base_sinimpuestos_format}}€</strong></a>
                <a [routerLink]="['/licitacion/id:'+item.id]">
                    <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado en-plazo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado anuncio-previo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado pendiente-de-adjudicacion">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado adjudicada">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado resuelta">{{item.estado}}</span>
                </a>
                <div class="desktop-hidden">
                    <a class="unfollow-button" (click)="DeleteLicitacionSeguida(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--    Adjudicaciones   -->
<div class="adjudicatarios-cards row" *ngIf="AdjudicatariosLista && AdjudicatariosLista.length">
    <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let item of AdjudicatariosLista">
        <div class="post-box">
            <a class="post-box-body" [routerLink]="['/adjudicatario/nombre:'+item]">
                <i class="icofont-bag-alt text-primary"></i>
                <h2>{{item}}</h2>
            </a>
        </div>
    </div>
</div>
<div id="content-licitaciones-cards" *ngIf="tipo == 'adjudicaciones'" class="two-columns-50">
    <div  *ngFor="let item of licitaciones">
        <a [routerLink]="['/licitacion/id:'+item.id]">
            <div class="card-licitacion" >
                <!-- <span class="fecha" *ngIf="item.fecha_inicio_format != null"><strong style="color: #2f3848;">Inicio:</strong> {{item.fecha_inicio_format}}</span><br/> -->
                <span class="fecha hidden-xs" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</span>
                <span class="fecha hidden-xs" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</span>
                <div class="titulo"><b class="text-primary">Exp: {{item.num_expediente}}</b></div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_actualizacion_format != null"><strong style="color: #2f3848;">Actualización:</strong> {{item.fecha_actualizacion_format}}</div>
                <div class="fecha desktop-hidden" *ngIf="item.fecha_fin_format != null"><strong style="color: #2f3848;">Fin:</strong> {{item.fecha_fin_format}}</div>
                <div class="desc">{{item.objeto_contrato}}</div>
                <div class="footer" style="margin-top: 8px;">
                    <span style="font-weight: 600; font-size: 11px;" class="ubicacion"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.poblacion}}, {{item.provincia}}</span>
                    <strong style="float: right;">{{item.presupuesto_base_sinimpuestos_format}}€</strong>
                    <span *ngIf="item.estado == 'EN PLAZO (PUB)'" class="estado en-plazo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ANUNCIO PREVIO (PRE)'" class="estado anuncio-previo">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'PENDIENTE DE ADJUDICACIÓN (EV)'" class="estado pendiente-de-adjudicacion">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'ADJUDICADA (ADJ)'" class="estado adjudicada">{{item.estado}}</span>
                    <span *ngIf="item.estado == 'RESUELTA (RES)'" class="estado resuelta">{{item.estado}}</span>
                </div>
            </div>
        </a>
    </div>
</div>
<div id="no-resultados" style="display: none">No se encontraron resultados...</div>