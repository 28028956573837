import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;

@Component({
  selector: 'app-ver-organismo',
  templateUrl: './ver-organismo.component.html'
})
export class VerOrganismoComponent implements OnInit {

  public organismo:any = {};
  public seguido:any = false;
  public licitaciones:any = [];
  public adjudicaciones:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    App.init();
    this.cargarDatosOrganismo();
  }
  cargarDatosOrganismo(){
    const queryString = window.location.pathname;
    var idOrganismo = queryString.split('id:')[1];
    if (!idOrganismo || idOrganismo == '') {
      this.ngZone.run(() => this.router.navigateByUrl('/inicio')).then();
      return;
    }
    this.globals.Api('/organismo', {id: idOrganismo}).subscribe(data => {
      if (!data || !data.length) return;
      this.organismo = data[0];
      this.CargarLicitaciones();
      this.CargarAdjudicaciones();
      // this.CargarMapaLocalidades();
      this.ComprobarSeguido();
    });
  }
  CargarLicitaciones(){
    this.globals.Api('/licitaciones', {nombre_organismo: this.organismo.nombre}).subscribe(data => {
      if (!data || !data.length) return;
      this.licitaciones = data;
    });
  }
  CargarAdjudicaciones(){
    this.globals.Api('/licitaciones', {nombre_organismo: this.organismo.nombre, adjudicaciones: true}).subscribe(data => {
      if (!data || !data.length) return;
      this.adjudicaciones = data;
    });
  }
  CargarMapaLocalidades(){
    $('.jvectormap-tip').remove();
    $("#mapa-localidades").append('<div id="map-action" style="display: none;"></div>');
    this.globals.Api('/licitaciones-mapa-ficha', {tipo: 'localidades', provincia: 'badajoz', codpostal: this.organismo.codpostal, localidad: this.organismo.poblacion}).subscribe(data => {
      if (!data || !data.length) return;
      let markers = [];
      let cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.coords.lat, item.coords.lon],
          name: item.localidad
        });
        cityAreaData.push(10);
      }
      
      let localidades = [];
      let map;
      let that = this;
      let container = $('.map-container');
      container.html('<div id="mapa-localidades" style="width:100%;height:350px;"></div>');
      $("#mapa-localidades").append('<div id="map-action" style="display: none;"></div>');
      $('#map-action').click(() => {
        //this.CargarMapa();
      });
      let mapa = $('#mapa-localidades').vectorMap({
        container: container,
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 6,
          animate: true
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {fill: '#e34d63'},
          selected: {fill: '#e34d63'}
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html(
            '<span class="lugar">'+el.html()+'</span>'
          );
        },
        onMarkerClick: function (event, code) {
          //that.buscadorComponent.AplicarBusquedaLocalidad(markers[code].name);
        }
      });
    });
  }
  ComprobarSeguido(){
    this.globals.Api('/organismo-comprobar-seguir', {id_organismo: this.organismo.id}).subscribe(data => {
      if (!data || data.length == 0){
        this.seguido = false;
        return;
      }else{
        this.seguido = true;
      }
    });
  }
  SeguirOrganismo(){
    this.globals.Api('/organismo-seguir', {id_organismo: this.organismo.id}).subscribe(data => {
      if (!data) return;
      this.seguido = true;
    });
  }
  DeleteOrganismoSeguido(){
    swal({
      title: "¿Dejar de seguir organismo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/organismo-seguidas-del', {id: this.organismo.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.ComprobarSeguido();
        this.appref.tick();
      });
    });
  }
}
