import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;


@Component({
  selector: 'app-edit-licitacion',
  templateUrl: './edit-licitacion.component.html'
})
export class EditLicitacionComponent implements OnInit {
  public adjudicaciones:boolean = (window.location.pathname.indexOf('adjudicaciones') != -1);
  public title:string = 'Nueva licitación';
  public data:any = {};
  public campos_bloqueados:any = [];
  public estados:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(2); //No afiliados
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (!this.globals.passData || !Object.keys(this.globals.passData).length) {
      this.adjudicaciones = (window.location.pathname.indexOf('adjudicaciones') != -1);
      this.Cancelar();
      return;
    }
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nueva licitación';
      if (this.adjudicaciones) this.title = 'Nueva adjudicación';
    } else {
      this.title = this.data.num_expediente;
    }
    this.globals.Api('/campos-bloqueados', {id_licitacion: this.data.id}).subscribe(data => {
      if (!data || data.length == 0) return;
      this.campos_bloqueados = data;
    });
    this.globals.Api('/estados').subscribe(data => {
      if (!data || data.length == 0) return;
      this.estados = data;
    });
    this.data.documentos_add = [];
    globalThis.originaldata = JSON.parse(JSON.stringify(this.data));
  }

  GetBloqueado(campo:string) {
    if (!campo || campo == '') return false;
    return (this.campos_bloqueados.find(el => el.campo == campo) != null);
  }
  BloquearDesbloquear(campo:string) {
    if (!campo || campo == '') return;
    if (globalThis.bloqueandocampo) return;
    globalThis.bloqueandocampo = true;
    this.globals.Api('/campos-bloqueados-add-remove', {id_licitacion: this.data.id, campo: campo}).subscribe(data => {
      if (!data || data.error) {
        globalThis.bloqueandocampo = false;
        return;
      }
      if (data.action == 'remove') this.campos_bloqueados.splice(this.campos_bloqueados.findIndex(el => el.campo == campo), 1);
      else this.campos_bloqueados.push({id_licitacion: this.data.id, campo: campo});
      globalThis.bloqueandocampo = false;
      this.appref.tick();
    }, error => {
      globalThis.bloqueandocampo = false;
    });
  }

  GetEstoyEditando() {
    let editando:boolean = false;
    if (JSON.stringify(globalThis.originaldata) !== JSON.stringify(this.data)) editando = true;
    if (this.data.lotes && this.data.lotes.filter(el => el.vacio == true).length) editando = true;
    if (this.data.ganador && this.data.ganador.filter(el => el.vacio == true).length) editando = true;
    if (this.data.documentos_add && this.data.documentos_add.length > 0) editando = true;
    return editando;
  }

  AddLote() {
    this.data.lotes.push({vacio: true});
  }
  DelLote(item) {
    if (item.vacio) {
      this.data.lotes.splice(this.data.lotes.findIndex(el => el == item), 1);
      return;
    }
    swal({
      title: "¿Borrar lote?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del-lote', {id_licitacion: item.id_licitacion, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (this.data && this.data.lotes) this.data.lotes.splice(this.data.lotes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  AddGanador() {
    this.data.ganador.push({vacio: true});
  }
  DelGanador(item) {
    if (item.vacio) {
      this.data.ganador.splice(this.data.ganador.findIndex(el => el == item), 1);
      return;
    }
    swal({
      title: "¿Borrar ganador?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del-ganador', {id_licitacion: item.id_licitacion, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (this.data && this.data.ganador) this.data.ganador.splice(this.data.ganador.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  AddDocumento() {
    let accept = 'image/*, application/*';
    let input = this.globals.FileInput(accept, event => {
      this.globals.Base64(input, base64 => {
        let extension = '.'+input.value.split('.')[input.value.split('.').length-1].toLocaleLowerCase();
        if (!this.data.documentos_add) this.data.documentos_add = [];
        this.data.documentos_add.push({
          nombre: input.value.replace(/.*[\/\\]/, '').replace(extension, ''),
          documento: base64,
          extension: extension
        });
      });
    });
    input.click();
  }
  DelDocumento(tipo:string) {
    this.data[tipo] = null;
  }
  DelDocumentoNuevo(item:any) {
    if (!this.data.documentos_add) return;
    this.data.documentos_add.splice(this.data.documentos_add.findIndex(el => el == item), 1);
  }
  DelDocumentoAdjuntado(item:any) {
    swal({
      title: "¿Borrar documento?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del-documento', {id_licitacion: item.id_licitacion, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (this.data && this.data.documentos) this.data.documentos.splice(this.data.documentos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  Cancelar() {
    if (this.adjudicaciones) {
      this.ngZone.run(() => this.router.navigateByUrl('/adjudicaciones')).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/licitaciones')).then();
  }
  Guardar() {
    if (!this.data.id || this.data.id == '') return;
    if (!this.GetEstoyEditando()) {
      swal('Guardado', 'Datos guardados correctamente', 'success');
      return;
    }
    if (
      !this.data.organo_contratacion
      || !this.data.estado
      || !this.data.objeto_contrato
      || !this.data.presupuesto_base_sinimpuestos
      || !this.data.presupuesto_base_conimpuestos
      || !this.data.poblacion
      || !this.data.provincia
      || this.data.organo_contratacion == ''
      || this.data.estado == ''
      || this.data.presupuesto_base_sinimpuestos == ''
      || this.data.presupuesto_base_conimpuestos == ''
      || this.data.poblacion == ''
      || this.data.provincia == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.lotes.find(el => !el.num_lote || el.num_lote == '')) {
      swal('Complete campos en lotes', 'Por favor, rellene los campos de lote para continuar', 'error');return;
    }
    if (this.data.ganador.find(el => !el.lote_adjudicatario || el.lote_adjudicatario == '')) {
      swal('Complete campos en lotes', 'Por favor, rellene los campos de lote para continuar', 'error');return;
    }
    swal({
      title: "¿Guardar datos?",
      text: "Las modificaciones pueden añadir histórico y enviar notificaciones",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/licitaciones-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        globalThis.originaldata = JSON.parse(JSON.stringify(this.data));
        setTimeout(() => {
          swal('Guardado', 'Datos guardados correctamente', 'success');
        }, 500);
        this.globals.Api('/licitaciones', {id: this.data.id}).subscribe(data => {
          if (!data || !data.length) return; 
          this.data = data[0];
          globalThis.originaldata = JSON.parse(JSON.stringify(this.data));
          this.appref.tick();
        });
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    });
  }
}
