import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { BackendDashboardComponent } from './backend/dashboard/dashboard.component';
import { EmpresasComponent } from './backend/empresas/empresas.component';
import { EditLicitacionComponent } from './backend/licitaciones/edit-licitacion/edit-licitacion.component';
import { LicitacionesComponent } from './backend/licitaciones/licitaciones.component';
import { LoginComponent } from './backend/login/login.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { OrganismosAdjudicatariosComponent } from './backend/organismos-adjudicatarios/organismos-adjudicatarios.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { AdjudicacionesComponent } from './frontend/adjudicaciones/adjudicaciones.component';
import { DashboardComponent } from './frontend/dashboard/dashboard.component';
import { PubMiPerfilComponent } from './frontend/mi-perfil/mi-perfil.component';
import { MisObrasComponent } from './frontend/mis-obras/mis-obras.component';
import { VerAdjudicatarioComponent } from './frontend/ver-adjudicatario/ver-adjudicatario.component';
import { VerLicitacionComponent } from './frontend/ver-licitacion/ver-licitacion.component';
import { VerOrganismoComponent } from './frontend/ver-organismo/ver-organismo.component';
import { TextoLegalComponent } from './frontend/texto-legal/texto-legal.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard', component: BackendDashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'afiliados', component: UsuariosComponent},
  {path: 'afiliados/add', component: EditUsuarioComponent},
  {path: 'afiliados/edit', component: EditUsuarioComponent},
  {path: 'licitaciones', component: LicitacionesComponent},
  {path: 'licitaciones/edit', component: EditLicitacionComponent},
  {path: 'adjudicaciones/edit', component: EditLicitacionComponent},
  {path: 'adjudicaciones', component: LicitacionesComponent},
  {path: 'organismos-licitadores', component: EmpresasComponent},
  {path: 'organismos-adjudicatarios', component: OrganismosAdjudicatariosComponent},
  //FrontEnd
  {path: 'inicio', component: DashboardComponent},
  {path: 'adjudicaciones-top', component: AdjudicacionesComponent},
  {path: 'mis-obras', component: MisObrasComponent},
  {path: 'mi-cuenta', component: PubMiPerfilComponent},
  {path: 'licitacion/:id', component: VerLicitacionComponent},
  {path: 'organismo/:id', component: VerOrganismoComponent},
  {path: 'adjudicatario/:nombre', component: VerAdjudicatarioComponent},
  {path: 'terminos-condiciones', component: TextoLegalComponent},
  {path: 'politica-privacidad', component: TextoLegalComponent},
  {path: 'politica-cookies', component: TextoLegalComponent},
  {path: 'eliminar-cuenta', component: TextoLegalComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
