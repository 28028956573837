import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-modal-licitacion',
  templateUrl: './modal-licitacion.component.html'
})
export class ModalLicitacionComponent implements OnInit {
  public data:any = {};
  public tipo:string = 'licitacion';
  public licitaciones:any = [];
  public selecteddata:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.modallicitacionshow = (data:any, tipo:string = 'licitacion') => {
      if (!data) return;
      this.data = data;
      this.tipo = tipo;
      // console.log(this.data);
      $('#modal-licitacion').modal('show');
      $('#modal-licitacion .modal-title').html('Licitación <b>' + this.data.num_expediente + '</b>');
      $('a[href="#tab-modal-general"]').trigger('click');
      setTimeout(() => {
        $('.nav-pills a').click(function (e) {
          e.preventDefault()
          $(this).tab('show');
        });
        if (this.data.datos_origen && this.data.datos_origen.length) {
          this.SelectOrigenDatos(this.data.datos_origen[0], 0);
        }
      }, 100);
    }
    $.modallicitacionesshow = (licitaciones:any, empresa:any) => {
      if (!licitaciones || !licitaciones.length) return;
      this.licitaciones = licitaciones;
      this.selecteddata = JSON.parse(JSON.stringify(this.licitaciones[0]));
      $('#modal-licitacion').modal('show');
      $('#modal-licitacion .modal-title').html('Licitaciones <b>' + empresa + '</b>');
      $('a[href="#tab-modal-general"]').trigger('click');
      setTimeout(() => {
        $('.nav-pills a').click(function (e) {
          e.preventDefault()
          $(this).tab('show');
        })
      }, 100);
    }
    $.modallicitacionhide = () => {
      $('#modal-licitacion').modal('hide');
    }
  }
  Editar() {
    $('#modal-licitacion').modal('hide');
    this.globals.passData = JSON.parse(JSON.stringify(this.data));
    if (this.tipo == 'licitacion') {
      this.ngZone.run(() => this.router.navigateByUrl('/licitaciones/edit')).then();
    } else {
      this.ngZone.run(() => this.router.navigateByUrl('/adjudicaciones/edit')).then();
    }
  }
  Borrar() {
    if ($.modallicitaciondelete) $.modallicitaciondelete(this.data);
  }
  SelectLicitacion(item:any) {
    this.selecteddata = JSON.parse(JSON.stringify(item));
    setTimeout(() => {
      $('.nav-pills a').click(function (e) {
        e.preventDefault()
        $(this).tab('show');
      })
    }, 100);
  }
  DelLote(item) {
    swal({
      title: "¿Borrar lote?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del-lote', {id_licitacion: item.id_licitacion, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (this.data && this.data.lotes) this.data.lotes.splice(this.data.lotes.findIndex(el => el.id == item.id), 1);
        if (this.selecteddata && this.selecteddata.lotes) this.selecteddata.lotes.splice(this.selecteddata.lotes.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  DelGanador(item) {
    swal({
      title: "¿Borrar ganador?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del-ganador', {id_licitacion: item.id_licitacion, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        if (this.data && this.data.ganador) this.data.ganador.splice(this.data.ganador.findIndex(el => el.id == item.id), 1);
        if (this.selecteddata && this.selecteddata.ganador) this.selecteddata.ganador.splice(this.selecteddata.ganador.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

  SelectOrigenDatos(item:any, index:any) {
    if (!item || !item.id) return;
    $('a[href="#tab-origen-datos-'+ index +'"]').tab('show');
    $('.tab-origen-datos').removeClass('active');
    $('#tab-origen-datos-'+index).addClass('active');
    $('#jstree-'+index).jstree('destroy');
    let data = JSON.parse(item.json);
    $('#txt-origen-datos-'+index).val(JSON.stringify(data, undefined, 2));
    data.id = 'Datos';
    data.text = 'Datos';
    data.state = {opened: true};
    data.children = [];
    let addchilds = (parent) => {
      if (typeof parent !== 'object') return;
      if (!Object.keys(parent).length) return;
      for (let key in parent) {
        let child = parent[key];
        if (typeof child === 'string') {
          if (!parent.children) parent.children = [];
          parent.children.push({
            text: child, state: {opened: true}
          });
          continue;
        }
        if (typeof child !== 'object') continue;
        if (child.length) {
          for (let item of child) {
            if (typeof item === 'string') {
              if (!parent.children) parent.children = [];
              parent.children.push({
                text: item, state: {opened: true}
              });
            }
            addchilds(item.children);
          }
          continue;
        }
        child.id = key;
        child.text = key;
        if (!parent.children) parent.children = [];
        parent.children.push(child);
        addchilds(child);
      }
    };
    for (let key in data.cacplaceext_ContractFolderStatus) {
      let child = data.cacplaceext_ContractFolderStatus[key];
      if (typeof child === 'string') {
        if (!data.children) data.children = [];
        data.children.push({
          text: child, state: {opened: true}
        });
        continue;
      }
      if (typeof child !== 'object') continue;
      if (child.length) {
        for (let item of child) {
          if (typeof item === 'string') {
            if (!data.children) data.children = [];
            data.children.push({
              text: item, state: {opened: true}
            });
          }
          if (item.children) addchilds(item.children);
        }
        continue;
      }
      child.id = key;
      child.text = key;
      data.children.push(child);
      addchilds(child);
    }
    setTimeout(() => {
      $('#jstree-'+index).jstree({
        core: {data: data}
      });
    }, 100);
  }
}
