import { ApplicationRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;

@Component({
  selector: 'app-buscador-nuevo',
  templateUrl: './buscador-nuevo.component.html',
  styleUrls: ['./buscador-nuevo.component.scss']
})
export class BuscadorNuevoComponent implements OnInit {
  @Input() tipo: string = '';
  public resultados:any = null;
  public licitaciones:any = [];
  public busqueda:string = '';
  public filtros:any = {
    nombre:     '',
    estado:     '',
    localidad:  '',
    expediente: '',
    busqueda:   '',
    provincia:  '',
    provincia_codigo: '',
    orden:      ''
  };
  public params:any = {
    page_index: 1,
    page_size: 5
  };
  public fullscreen:boolean = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals) {
  }

  ngOnInit(): void {
    if (this.tipo == 'dashboard') this.CargarLicitaciones();
    window.onclick = function(e:any) {   
      if (!document.querySelector('#content-search') || !document.querySelector('#filter-search')) {
        $('#content-search').hide();
        return;
      }
      if (document.querySelector('#content-search').contains(e.target) || document.querySelector('#filter-search').contains(e.target)) {
      } else{
        $('#content-search').hide();
      }
    }
  }
  CargarLicitaciones() {
    this.params.page_size = 5;
    if (this.globals.GetData('ultimas-licitaciones')) this.licitaciones = this.globals.GetData('ultimas-licitaciones');
    this.globals.Api('/licitaciones', {page_index: 1, page_size: 5, estado: 'EN PLAZO (PUB)'}).subscribe(data => {
      if (!data || !data.length) return;
      this.licitaciones = data;
      this.globals.SaveData(data, 'ultimas-licitaciones');
    });
  }

  MostrarMapa() {
    $('#column-buscador').css('width','');
    $('#button-mostrar-mapa').hide();
    $('#column-map').show();
    $('#content-licitaciones-cards').removeClass('two-columns-50');
    this.licitaciones = [];
    this.fullscreen = false;
    this.resultados = null;
    this.params.total = null;
    this.busqueda = null;
    this.params.page_size = 5;
    this.CargarLicitaciones();
  }
  MostrarComboBuscar() {
    if (this.busqueda.length) {
      $('#content-search').show();
    }
  }
  Buscar(event:any) {
    if (event.keyCode == 13) {
      this.resultados = null;
      return;
    }
    clearTimeout($.IDTimeBuscar);
    $.IDTimeBuscar = setTimeout(() => {
      if (this.busqueda.length > 2) {
        if (this.fullscreen) return;
        $('#content-loading').show();
        $('#content-search').show();
        if(this.tipo == 'dashboard') {
          this.BuscarDashboard();
        }
        // if(this.tipo == 'mis-obras') {
        //   this.BuscarMisLicitaciones();
        // }
        // if(this.tipo == 'adjudicaciones') {
        //   this.BuscarAdjudicaciones();
        // }
      } else {
        $('#content-loading').hide();
        this.resultados = null;
        this.appref.tick();
      }
    }, 400);
  }
  BuscarDashboard() {
    let params:any = {
      filtro: this.busqueda,
      modalbuscador: true,
      page_index: 1,
      page_size: 3
    };
    this.globals.Api('/licitaciones-busqueda', params).subscribe((data:any) => {
      $('#content-loading').hide();
      if (!data) return;
      this.resultados = data;
    });
  }
  CargarTodosLosResultados() {
    if (this.busqueda.length <= 2) return;
    this.resultados = null;
    this.AplicarFiltro(this.filtros.localidad, this.filtros.estado, this.filtros.orden);
  }
  FiltrarProvincia(provincia_codigo:any = '', provincia:any = '', estado:any = '', orden:any = '', ocultarmapa:boolean = true) {
    $('#content-loading-2').show();
    this.filtros.provincia_codigo = provincia_codigo;
    this.filtros.provincia = provincia;
    this.filtros.localidad = '';
    this.filtros.estado = estado;
    this.filtros.orden = orden;
    if (this.tipo == 'dashboard') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.params.page_index = 1;
      this.CargarGrid();
    }
  }
  AplicarFiltro(localidad:any = '', estado:any = '', orden:any = '', ocultarmapa:boolean = true) {
    $('#content-loading-2').show();
    this.filtros.localidad = localidad;
    this.filtros.estado = estado;
    this.filtros.orden = orden;
    if (this.tipo == 'dashboard') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.params.page_index = 1;
      if (ocultarmapa) this.params.page_size = 12;
      this.CargarGrid();
      if (ocultarmapa) this.BusquedaFullWidth();
    }
  }
  MostrarFiltroEstado() {
    $('#content-orden-selector').hide();
    $('#content-estados-selector').toggle();
  }
  MostrarFiltroOrden() {
    $('#content-estados-selector').hide();
    $('#content-orden-selector').toggle();
  }
  ClearFiltro(tipofiltro:string) {
    $('#content-loading-2').show();
    if (tipofiltro == 'provincia_codigo') this.filtros.provincia_codigo = '';
    if (tipofiltro == 'provincia') this.filtros.provincia = '';
    if (tipofiltro == 'localidad') this.filtros.localidad = '';
    if (tipofiltro == 'estado') this.filtros.estado = '';
    if (tipofiltro == 'orden') this.filtros.orden = '';
    if (tipofiltro == 'busqueda') this.busqueda = '';
    this.params.page_index = 1;
    this.CargarGrid();
    
    if (tipofiltro == 'provincia_codigo') {
      if ($.CargarMapa) $.CargarMapa();
    }
  }
  ClearAllFiltros() {
    $('#content-loading-2').show();
    this.filtros.provincia_codigo = '';
    this.filtros.provincia = '';
    this.filtros.localidad = '';
    this.filtros.estado = '';
    this.filtros.orden = '';
    this.busqueda = '';
    this.params.page_size = 5;
    this.params.page_index = 1;
    this.CargarGrid();
  }
  
  BusquedaFullWidth() {
    this.fullscreen = true;
    $('#column-map').hide();
    $('#column-buscador').css('width','100%');
    $('#button-mostrar-mapa').show();
    if ($(window).width() > 768) {
      $('#content-licitaciones-cards').addClass('two-columns-50');
    }
  }
  CargarGrid(paginationload:boolean = true) {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando ...'
    });
    this.params.provincia_codigo = this.filtros.provincia_codigo;
    this.params.provincia = this.filtros.provincia;
    this.params.localidad = this.filtros.localidad;
    this.params.estado = this.filtros.estado;
    this.params.filtro = this.busqueda;
    this.params.orden = this.filtros.orden;
    this.globals.Api('/licitaciones-busqueda', this.params).subscribe((data:any) => {
      $.LoadingOverlay("hide", true);
      $('#content-loading-2').hide();
      this.resultados = null;
      if (!data || !data.length) {
        this.licitaciones = [];
        this.resultados = null;
        this.params.total = null;
        this.appref.tick();
        return;
      }
      this.licitaciones = data;
      this.licitaciones.forEach(element => {
        element.presupuesto_base_conimpuestos = new Intl.NumberFormat().format(element.presupuesto_base_conimpuestos);
      });
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/licitaciones-busqueda/total', this.params).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    }, error => {
      $.LoadingOverlay("hide", true);
      $('#content-loading-2').hide();
      this.resultados = null;
      this.params.total = null;
      this.appref.tick();
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
}
