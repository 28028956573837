
<header class="navbar navbar-inverse navbar-front navbar-fixed-top text-center">
    <!-- Left Header Navigation -->
    <ul class="nav navbar-nav-custom" style="float:none;display:inline-block;">
        <!-- Main Sidebar Toggle Button -->
        <li class="desktop-hidden btn-menu">
            <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
            </a>
        </li>
        <!-- END Main Sidebar Toggle Button -->

        <!-- Header Link -->
        <li class="animation-fadeInQuick logo">
            <a [routerLink]="['/inicio']"><img src="assets/logo-header-fondorojo.png" alt=""> </a>
        </li>
        <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/inicio']" routerLinkActive="active">Inicio</a>
        </li>
        <!-- <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/lp-licitaciones']">Licitaciones</a>
        </li> -->
        <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/adjudicaciones-top']" routerLinkActive="active">Adjudicaciones</a>
        </li>
        <li class="hidden-xs animation-fadeInQuick">
            <a [routerLink]="['/mis-obras']" routerLinkActive="active">Mis obras</a>
        </li>
        <!-- END Header Link -->
        <!-- Search Form - ->
        <li>
            <form action="page_ready_search_results.html" method="post" class="navbar-form-custom">
                <input type="text" id="top-search" name="top-search" class="form-control" placeholder="Search..">
            </form>
        </li>
        <!- - END Search Form -->

        <!-- User Dropdown -->
        <li class="dropdown">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                <ng-container *ngIf="globals && globals.me && globals.me.imagen && globals.me.imagen != ''">
                    <img src="{{globals.me.imagen}}" alt="avatar" onerror="this.src = 'assets/icon-user.svg'">
                </ng-container>
                <ng-container *ngIf="!globals || !globals.me || !globals.me.imagen || globals.me.imagen == ''">
                    <img src="assets/icon-user.svg" alt="avatar">
                </ng-container>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-header">
                    <strong>CUENTA</strong>
                </li>
                <li>
                    <a [routerLink]="['/mi-cuenta']" class="cursor">
                        <i class="fa fa-pencil-square fa-fw pull-right"></i>
                        Mi perfil
                    </a>
                </li>
                <li class="divider"><li>
                <li>
                    <a (click)="Logout()" class="cursor">
                        <i class="fa fa-power-off fa-fw pull-right"></i>
                        Cerrar sesión
                    </a>
                </li>
            </ul>
        </li>
        <!-- END User Dropdown -->
    </ul>
    <!-- END Left Header Navigation -->
</header>