<div id="page-wrapper">
    <div id="page-container" class="frontend header-fixed-top">
        <header class="navbar navbar-inverse navbar-front navbar-fixed-top text-center">
            <!-- Left Header Navigation -->
            <ul class="nav navbar-nav-custom" style="float:none;display:inline-block;">
                <!-- Main Sidebar Toggle Button -->
                <li class="desktop-hidden btn-menu">
                    <a href="javascript:void(0)" onclick="App.sidebar('toggle-sidebar');this.blur();">
                        <i class="fa fa-ellipsis-v fa-fw animation-fadeInRight" id="sidebar-toggle-mini"></i>
                        <i class="fa fa-bars fa-fw animation-fadeInRight" id="sidebar-toggle-full"></i>
                    </a>
                </li>
                <!-- END Main Sidebar Toggle Button -->

                <!-- Header Link -->
                <li class="animation-fadeInQuick logo">
                    <a [routerLink]="['/login']"><img src="assets/logo-header-fondorojo.png" alt=""> </a>
                </li>
            </ul>
            <!-- END Left Header Navigation -->
        </header>
        <div class="texto-legal container">
            <div [innerHTML]="texto"></div>
        </div>
    </div>
    <app-footer></app-footer>
</div>