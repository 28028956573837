<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top" *ngIf="licitacion">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-header></app-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="container p-t-20 p-b-20">
                    <!-- <h1 class="logo-home">
                        <img src="assets/logo-isotipo.svg"> <strong>LicitaPYME</strong>
                    </h1> -->
                    <!-- Fila 1-->
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div>
                                <h1><b class="text-primary">{{licitacion.num_expediente}}</b>
                                    <span *ngIf="seguida == false" class="btn-seguir-licitacion" (click)="SeguirLicitacion()"><i class="icofont-ui-love"></i> Seguir</span>
                                    <span *ngIf="seguida == true" class="btn-seguir-licitacion" (click)="DeleteLicitacionSeguida()" style="color: white"><i class="icofont-ui-love" style="color: #e34d63"></i> Siguiendo</span>
                                </h1>
                                <p class="name-licitacion">{{licitacion.objeto_contrato}}</p>
                                <h2 class="text-primary">Datos generales</h2>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.organo_contratacion"><b class="titleData">Órgano de contratación: </b><span><a [routerLink]="['/organismo/id:'+idOrganismo]">{{licitacion.organo_contratacion}}</a></span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.estado"><b class="titleData">Estado de la licitación: </b><span>{{licitacion.estado}}</span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.presupuesto_base_sinimpuestos_format"><b class="titleData">Presupuesto base de licitación sin impuestos: </b><span>{{licitacion.presupuesto_base_sinimpuestos_format}}<span *ngIf="licitacion.presupuesto_base_sinimpuestos_format != 'No establecido'">€</span></span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.valor_estimado_format"><b class="titleData">Valor estimado del contrato: </b><span>{{licitacion.valor_estimado_format}}<span *ngIf="licitacion.valor_estimado_format != 'No establecido'">€</span></span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.tipo_contrato"><b class="titleData">Tipo de contrato: </b><span>{{licitacion.tipo_contrato}}</span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.cpv"><b class="titleData">Código CPV: </b><span>{{licitacion.cpv}}</span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.poblacion"><b class="titleData">Lugar de ejecución: </b><span>{{licitacion.poblacion}} ({{licitacion.provincia}})</span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.tipo_prodedimiento"><b class="titleData">Procedimiento de contratación: </b><span>{{licitacion.tipo_prodedimiento}}</span></p>
                                <p class="no-margin-bottom" *ngIf="licitacion && licitacion.fecha_fin_format"><b class="titleData">Fecha fin de presentación de oferta: </b><span>{{licitacion.fecha_fin_format}}</span></p>
                                <a href="{{licitacion.url}}" target="_blank">Acceder a la plataforma de contratación:</a><br/>
                                <a href="{{licitacion.url}}" target="_blank">{{licitacion.url}}</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div *ngIf="licitacion.documentos != '' || licitacion.pliego_prescripciones_tecnicas != '' || licitacion.pliego_clausulas_administrativas != '' || licitacion.anexos_pliegos != null">
                                <h2 class="text-primary">Documentación</h2>
                                <p class="no-margin-bottom" *ngIf="licitacion.pliego_prescripciones_tecnicas && licitacion.pliego_prescripciones_tecnicas != ''"><a href="{{licitacion.pliego_prescripciones_tecnicas}}" target="_blank"><i class="icofont-ui-file" style="margin-right: 5px;font-size: 18px;"></i> Pliego prescripciones técnicas.pdf</a></p>
                                <p class="no-margin-bottom" *ngIf="licitacion.pliego_clausulas_administrativas && licitacion.pliego_clausulas_administrativas != ''"><a href="{{licitacion.pliego_clausulas_administrativas}}" target="_blank"><i class="icofont-ui-file" style="margin-right: 5px;font-size: 18px;"></i> Pliego cláusulas administrativas.pdf</a></p>
                                <p class="no-margin-bottom" *ngIf="licitacion.anexos_pliegos != null"><a href="{{licitacion.anexos_pliegos}}" target="_blank"><i class="icofont-ui-file" style="margin-right: 5px;font-size: 18px;"></i> {{licitacion.id_anexos_pliegos}}</a></p>
                                <h4 *ngIf="licitacion.documentos != ''" class="titleData">Documentos adicionales:</h4>
                                <p class="no-margin-bottom" *ngFor="let documento of licitacion.documentos"><a href="{{documento.url}}" target="_blank"><i class="icofont-ui-file" style="margin-right: 5px;font-size: 18px;"></i>{{documento.nombre}}</a></p>
                            </div>
                            <div *ngIf="licitacion.cambios != ''">
                                <h2 class="text-primary">Histórico</h2>
                                <div class="historico" *ngFor="let cambio of licitacion.cambios">
                                    <div><i class="icofont-clock-time" style="font-size: 16px;"></i> Actualizado el {{cambio.alta_format}}</div>
                                    <div [innerHTML]="cambio.cambios"  class="no-margin-bottom"></div>
                                </div>
                            </div>
                            <!-- <div id="mapa" style="margin-top: 10px;">
                                <div id="mapa-localidades" style="width:100%;height:250px;"></div>
                            </div> -->
                            <hr>
                            <p class="font-12">¿Has tenido problemas con la plataforma o has visto algún dato erróneo? Puedes enviarnos tu reporte aquí</p>
                            <a class="btn btn-primary m-t--10" data-toggle="modal" href="#modal-reporte">Enviar reporte <i class="fa fa-file-text m-l-10"></i></a>

                        </div>
                    </div>
                    <div class="row m-b-60">
                        <div class="col-md-6 col-sm-12" *ngIf="licitacion.lotes != ''">
                            <div *ngIf="licitacion.lotes != ''">
                                <h2 class="text-primary">Lotes</h2>
                                <div class="cards-lotes" *ngFor="let lote of licitacion.lotes">
                                    <!-- <p class="no-margin-bottom" *ngIf="lote.resultado != null"><b class="titleData">Resultado: </b><span>{{lote.resultado}}</span></p> -->
                                    <p class="no-margin-bottom" *ngIf="lote.objeto_contrato != null"><b class="titleData">Lote: </b><span>{{lote.objeto_contrato}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.num_lote != null"><b class="titleData">Número de lote: </b><span>{{lote.num_lote}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.lote_adjudicatario != null"><b class="titleData">Nombre adjudicatario: </b><span>{{lote.lote_adjudicatario}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.num_licitadores_presentados != null"><b class="titleData">Nº de Licitadores Presentados: </b><span>{{lote.num_licitadores_presentados}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.lote_importe_sinimpuestos_format != null"><b class="titleData">Importe de Adjudicación sin impuestos: </b><span>{{lote.lote_importe_sinimpuestos_format}}€</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.lote_importe_conimpuestos_format != null"><b class="titleData">Importe de Adjudicación con impuestos: </b><span>{{lote.lote_importe_conimpuestos_format}}€</span></p>
                                    <p class="no-margin-bottom" *ngIf="lote.lote_lugar_ejecucion != null"><b class="titleData">Lugar de ejecución: </b><span>{{lote.lote_lugar_ejecucion}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div *ngIf="ganadores && ganadores.length && ganadores[0].num_lote != ''">
                                <h2 class="text-primary">Ganadores</h2>
                                <div class="cards-lotes" *ngFor="let ganador of ganadores">
                                    <p class="no-margin-bottom" *ngIf="ganador.num_lote != null"><b class="titleData">Número de lote: </b><span>{{ganador.num_lote}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.num_contrato != null"><b class="titleData">Número de contrato: </b><span>{{ganador.num_contrato}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.lote_adjudicatario != null"><b class="titleData">Nombre adjudicatario: </b><span><a [routerLink]="['/adjudicatario/nombre:'+ganador.lote_adjudicatario]">{{ganador.lote_adjudicatario}}</a></span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.num_licitadores_presentados != null"><b class="titleData">Nº de Licitadores Presentados: </b><span>{{ganador.num_licitadores_presentados}}</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.lote_importe_sinimpuestos != null"><b class="titleData">Importe de Adjudicación sin impuestos: </b><span>{{ganador.lote_importe_sinimpuestos_format}}€</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.lote_importe_conimpuestos != null"><b class="titleData">Importe de Adjudicación con impuestos: </b><span>{{ganador.lote_importe_conimpuestos_format}}€</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.baja != null"><b class="titleData">Baja: </b><span>{{ganador.baja}}%</span></p>
                                    <p class="no-margin-bottom" *ngIf="ganador.lote_lugar_ejecucion != null"><b class="titleData">Lugar de ejecución: </b><span>{{ganador.lote_lugar_ejecucion}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
        <app-footer></app-footer>
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-reporte" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="max-width: 950px;">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title">Enviar reporte</h4>
        </div>
        <div class="modal-body p-t-0">
            <p class="font-12">¿Has tenido problemas con la plataforma o has visto algún dato erróneo? Puedes enviarnos tu reporte aquí</p>
            
          <div class="row">
            <div class="col-md-4 col-xs-12">
                <h3 class="font-14">Adjuntar documentos / imágenes</h3>
                <a class="btn btn-default btn-block" (click)="AddDocReporte()">Seleccionar documento <i class="fa fa-upload"></i></a>
                <ng-container *ngIf="reporte.documentos">
                    <hr>
                    <div class="m-b-10 font-bold font-14" *ngFor="let item of reporte.documentos">
                        <i class="fa fa-file-text m-r-10"></i> {{item.nombre}} <a class="btn btn-xs pull-right" (click)="DelDocReporte(item)"><i class="fa fa-trash"></i></a>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-8 col-xs-12">
                <div class="form-group">
                  <label>Título <span class="text-primary">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="reporte.titulo" maxlength="100">
                </div>
                <div class="form-group">
                  <label>Mensaje <span class="text-primary">*</span></label>
                  <textarea class="form-control" [(ngModel)]="reporte.mensaje" rows="10" maxlength="5000"></textarea>
                </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btn-enviar-reporte" type="button" class="btn btn-primary" (click)="EnviarReporte()">Enviar reporte</button>
        </div>
      </div>
  </div>
</div>