<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                
                <ng-container *ngIf="globals && globals.me">
                    <!-- ADMIN -->
                    <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1">

                        <ul class="nav nav-pills m-b-10" data-toggle="tabs">
                            <li class="active"><a href="#tab-dashboad-general">Datos generales</a></li>
                            <li><a href="#tab-dashboad-reportes"><i class="fa fa-file-text"></i> Reportes</a></li>
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane active" id="tab-dashboad-general">
                
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-5" *ngIf="!email_fullscreen">
            
                                    <h4>Licitaciones</h4>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/licitaciones']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-success">
                                                        <i class="icofont-handshake-deal text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-success" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_licitaciones">{{data.total_licitaciones}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Licitaciones</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/adjudicaciones']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-danger">
                                                        <i class="icofont-law-document text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-danger" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_adjudicaciones">{{data.total_adjudicaciones}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Adjudicaciones</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/organismos-licitadores']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-warning">
                                                        <i class="icofont-bag-alt text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-warning" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_empresas">{{data.total_empresas}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Licitadores</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/organismos-adjudicatarios']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left bg-02">
                                                        <i class="icofont-bag-alt text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 color-02" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_adjudicatarios">{{data.total_adjudicatarios}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Adjudicatarios</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/afiliados']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="icofont-ui-user-group text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-dark" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_afiliados">{{data.total_afiliados}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Afiliados</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/afiliados/add']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-dark">
                                                        <i class="icofont-ui-user text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3">
                                                        <strong><i class="icofont-plus-circle"></i></strong>
                                                    </h2>
                                                    <span class="text-muted">Nuevo afiliado</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <h4>Equipo</h4>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/usuarios']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-info">
                                                        <i class="icofont-people text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3 text-info" *ngIf="data">
                                                        <strong>&nbsp;<span data-toggle="counter" [attr.data-to]="data.total_usuarios">{{data.total_usuarios}}</span></strong>
                                                    </h2>
                                                    <span class="text-muted">Usuarios</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                            <a [routerLink]="['/usuarios/add']" class="widget">
                                                <div class="widget-content widget-content-mini text-right clearfix">
                                                    <div class="widget-icon pull-left themed-background-info">
                                                        <i class="icofont-ui-user text-light-op"></i>
                                                    </div>
                                                    <h2 class="widget-heading h3">
                                                        <strong><i class="icofont-plus-circle"></i></strong>
                                                    </h2>
                                                    <span class="text-muted">Nuevo usuario</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <div class="{{!email_fullscreen ? 'col-sm-12 col-md-6 col-lg-7' : null}}">
                                    <div class="widget">
                                        <div class="widget-content">
                                            <h4>Estadísticas email 
                                                <a class="btn btn-xs m-l-10" (click)="CargarDatosEmail()"><i class="fa fa-refresh"></i></a>
                                                <a class="btn btn-xs m-l-10" (click)="email_fullscreen = !email_fullscreen"><i class="fa fa-arrows-alt"></i></a>
                                            </h4>
                                            <div>
                                                <div class="row m-b-20">
                                                    <div class="col-xs-6">
                                                        <div class="form-group">
                                                            Fecha: <input type="date" class="form-control inline-block" style="width:auto;"
                                                            [(ngModel)]="paramsemail.fecha"
                                                            (change)="CargarDatosEmail()">
                                                        </div>
                                                        <ng-container *ngIf="data && data.email_estadisticas">
                                                            <div>Total emails enviados: <b>{{data.email_estadisticas.total}}</b></div>
                                                            <div>Leídos: <b>{{data.email_estadisticas.leidos}}</b></div>
                                                            <div>No leídos: <b>{{data.email_estadisticas.noleidos}}</b></div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <div>
                                                            <canvas id="chart-email" style="max-height:100px"></canvas>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="!data || !data.email_logs || !data.email_logs.length">
                                                    <div class="no-resultados">
                                                        Sin datos para la fecha seleccionada
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="data && data.email_logs && data.email_logs.length">
                                                    <input type="search" class="form-control" placeholder="Buscar envío"
                                                    [(ngModel)]="paramsemail.filtro"
                                                    (input)="InputCargarDatosEmail()">
                                                    <div [style]="!email_fullscreen ? 'max-height:37vh;overflow: auto;' : null">
                                                        <table class="table table-striped table-bordered table-vcenter table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th order="usuario">Destinatario</th>
                                                                    <th order="estado" style="width:80px">Estado</th>
                                                                    <th order="exception" style="width:120px">Error</th>
                                                                    <th order="leido" style="width:70px">Leído</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of data.email_logs">
                                                                    <td style="word-break: break-all;">
                                                                        <div *ngIf="item.usuario">{{item.usuario}}</div>
                                                                        <div *ngIf="item.empresa">{{item.empresa}}</div>
                                                                        <div class="font-bold">{{item.destinatario}}</div>
                                                                    </td>
                                                                    <td>
                                                                        <b *ngIf="item.estado == 'Success'" class="text-success">{{item.estado}}</b>
                                                                        <b *ngIf="item.estado == 'Error'" class="text-danger">{{item.estado}}</b>
                                                                        <div class="font-10">{{item.asunto}}</div>
                                                                    </td>
                                                                    <td class="font-10">{{item.exception}}</td>
                                                                    <td class="text-center">
                                                                        <b *ngIf="item.leido" class="badge" style="background-color:#AFDE5C;">Leído</b>
                                                                        <b *ngIf="!item.leido" class="badge">No leído</b>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!email_fullscreen">
                                <div class="col-sm-12 col-md-6 col-lg-5">
                                    <div class="widget">
                                        <div class="widget-content">
                                            <h4>Acciones en emails por tipo</h4>
                                            <div style="max-height:40vh;overflow: auto;">
                                                <ng-container *ngIf="!data || !data.email_clicks_portipo">
                                                    <div class="no-resultados">
                                                        Aún no se han recibido datos
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="data && data.email_clicks_portipo">
                                                    <table class="table table-striped table-bordered table-vcenter table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Tipo</th>
                                                                <th style="width:70px">Total Clics</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>URL LicitaCON</td>
                                                                <td class="text-center">
                                                                    <b class="badge" style="background-color:var(--color-primary);">
                                                                    {{data.email_clicks_portipo.click_url_licitacon}}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Seguir licitación</td>
                                                                <td class="text-center">
                                                                    <b class="badge" style="background-color:var(--color-primary);">
                                                                    {{data.email_clicks_portipo.click_seguir_licitacion}}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Contratación del Estado</td>
                                                                <td class="text-center">
                                                                    <b class="badge" style="background-color:var(--color-primary);">
                                                                    {{data.email_clicks_portipo.click_contratacion_estado}}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Descargar iOS <i class="fa fa-apple"></i></td>
                                                                <td class="text-center">
                                                                    <b class="badge" style="background-color:var(--color-primary);">
                                                                    {{data.email_clicks_portipo.click_descargar_ios}}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Descargar Android <i class="fa fa-android"></i></td>
                                                                <td class="text-center">
                                                                    <b class="badge" style="background-color:var(--color-primary);">
                                                                    {{data.email_clicks_portipo.click_descargar_android}}</b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-7">
                                    <div class="widget">
                                        <div class="widget-content">
                                            <h4>Registro de acciones en emails</h4>
                                            <div style="max-height:40vh;overflow: auto;">
                                                <ng-container *ngIf="!data || !data.email_clicks || !data.email_clicks.length">
                                                    <div class="no-resultados">
                                                        Aún no se han recibido datos
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="data && data.email_clicks && data.email_clicks.length">
                                                    <table class="table table-striped table-bordered table-vcenter table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>URL</th>
                                                                <th style="width:165px">Dispositivo</th>
                                                                <th style="width:70px">Total Clics</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of data.email_clicks">
                                                                <td style="word-break: break-all;"><a href="{{item.url}}" target="_blank">{{item.url}}</a></td>
                                                                <td>
                                                                    <div>{{item.device}} {{item.browser}}</div>
                                                                    <div>{{item.ip}}</div>
                                                                </td>
                                                                <td class="text-center"><b class="badge" style="background-color:var(--color-primary);">{{item.clicks}}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    

                          </div>
                          <div class="tab-pane" id="tab-dashboad-reportes">
                            <h4>Reportes</h4>
                            <div class="m-b-40">
                                <ng-container *ngIf="!data || !data.reportes || !data.reportes.length">
                                    <div class="no-resultados">
                                        Aún no se han recibido reportes
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="data && data.reportes && data.reportes.length">
                                    <div class="widget cursor" *ngFor="let item of data.reportes" (click)="VerReporte(item)">
                                        <div class="widget-content clearfix {{item.id_estado == 1 ? 'themed-background-warning' : ''}}">
                                            <div class="widget-icon pull-right">
                                                <i class="fa fa-file-text {{item.id_estado == 1 ? 'text-light-op' : ''}}"></i>
                                            </div>
                                            <h2 class="widget-heading h3 font-16"><strong>{{item.titulo}}</strong></h2>
                                            <span class="{{item.id_estado == 1 ? 'font-bold' : ''}}">{{item.estado}}</span><br>
                                            <span class="{{item.id_estado == 1 ? 'text-light-op' : ''}}">Fecha: {{item.alta_format}}</span><br>
                                            <span class="{{item.id_estado == 1 ? 'text-light-op' : ''}}" *ngIf="item.licitacion && !item.adjudicacion">Licitación: {{item.licitacion}}</span>
                                            <span class="{{item.id_estado == 1 ? 'text-light-op' : ''}}" *ngIf="item.licitacion && item.adjudicacion">Adjudicación: {{item.licitacion}}</span>
                                            <br>
                                            <span class="{{item.id_estado == 1 ? 'text-light-op' : ''}}" *ngIf="item.usuario">{{item.usuario}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                          </div>
                        </div>
        
                    </ng-container>
                    
                </ng-container>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-reporte" tabindex="-1" role="dialog"  aria-hidden="true" style="z-index: 1049;">
  <div class="modal-dialog modal-lg" style="max-width: 950px;">
      <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
            <div class="pull-right m-r-30 m-t--30 mobile-hidden">
              <a (click)="BorrarReporte()" class="btn btn-default btn-sm"><i class="fa fa-trash"></i> Borrar</a>
            </div>
        </div>
        <div class="modal-body p-t-0">
            
          <div class="row">
            <div class="col-md-4 col-xs-12">
                <h3 class="font-14">Documentos adjuntos</h3>
                <hr>
                <div class="no-resultados" *ngIf="!reporte.documentos || !reporte.documentos.length">
                    No se han adjuntado documentos
                </div>
                <ng-container *ngFor="let item of reporte.documentos">
                    <a class="m-b-10 font-bold font-14 display-block" href="{{item.documento}}" target="_blank">
                        <i class="fa fa-file-text m-r-10"></i> {{item.nombre}}{{item.extension}}
                    </a>
                </ng-container>
            </div>
            <div class="col-md-8 col-xs-12">
                <h3>{{reporte.titulo}}</h3>
                <div class="m-b-10">Estado: 
                    <select id="cmbestadoreporte" class="form-control inline-block m-l-20" style="width:auto;" (change)="ChangeEstadoReporte()">
                        <option *ngFor="let item of estados_reportes" [value]="item.id">{{item.estado}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Mensaje</label>
                    <p [innerHTML]="reporte.mensaje"></p>
                </div>
                <div class="m-b-10">
                    <div *ngIf="reporte.usuario">Enviado por: <b>{{reporte.usuario}}</b></div>
                    <div *ngIf="reporte.email">Email: <b><a href="mailto:{{reporte.email}}">{{reporte.email}}</a></b></div>
                </div>
                <a class="btn btn-primary" *ngIf="reporte.licitacion" (click)="VerLicitacion()">Ver licitacion</a>
            </div>
          </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
  </div>
</div>