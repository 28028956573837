import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-licitaciones',
  templateUrl: './licitaciones.component.html'
})
export class LicitacionesComponent implements OnInit {
  public adjudicaciones:boolean = (window.location.pathname.indexOf('adjudicaciones') != -1);
  public title:string = 'Licitaciones';
  public licitaciones = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(2); //No afiliados
    if ($.paramslicitaciones) this.params = $.paramslicitaciones;
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    if (this.adjudicaciones) this.title = 'Adjudicaciones';
    $.modallicitaciondelete = item => {
      this.Borrar(item);
    };
    // if ($.importandolicitaciones) {
    //   $.buttonloading('btnimportacion', 'Importando');
    //   $('.progress').show();
    // } else {
    //   $('#btnimportacion').html(`<i class="fa fa-download"></i> Importar`);
    // }
    if (this.globals.GetQueryString('importar') == 'true') {
      this.Importar();
    }
  }

  CargarGrid(paginationload:boolean = true) {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando ...'
    });
    // if (this.globals.GetData()) this.licitaciones = this.globals.GetData();
    $.paramslicitaciones = this.params;
    if (this.adjudicaciones) this.params.adjudicaciones = true;
    else this.params.adjudicaciones = null;
    this.globals.Api('/licitaciones', this.params).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || !data.length) {
        this.licitaciones = [];
        // this.globals.SaveData([]);
        return;
      }
      // this.globals.SaveData(data);
      this.licitaciones = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/licitaciones/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  FiltrarEstado(estado:any = null) {
    this.params.estado = estado;
    this.params.page_index = 1;
    this.CargarGrid();
  }
  VistaPrevia(item:any) {
    if ($.modallicitacionshow) $.modallicitacionshow(item, (!this.adjudicaciones ? 'licitacion' : 'adjudicacion'));
  }
  Nuevo() {
    this.globals.passData = '';
    if (!this.adjudicaciones) {
      this.ngZone.run(() => this.router.navigateByUrl('/licitaciones/add')).then();
    } else {
      this.ngZone.run(() => this.router.navigateByUrl('/adjudicaciones/add')).then();
    }
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    if (!this.adjudicaciones) {
      this.ngZone.run(() => this.router.navigateByUrl('/licitaciones/edit')).then();
    } else {
      this.ngZone.run(() => this.router.navigateByUrl('/adjudicaciones/edit')).then();
    }
  }
  CambiarVisibilidad(item:any) {
    this.globals.Api('/editar-licitacion-visible', {id: item.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      item.visible = data.value;
      this.appref.tick();
    });
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar licitación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.licitaciones.splice(this.licitaciones.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
        if ($.modallicitacionhide) $.modallicitacionhide();
      });
    });
  }
  Importar() {
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.2)',
    //   imageResizeFactor: 0.5,
    //   text: 'Importando licitaciones ...'
    // });
    // this.globals.Api('/importar-licitaciones').subscribe(data => {
    //   $.LoadingOverlay("hide", true);
    //   if (!data || data.error) {
    //     swal('Error', 'Se ha producido un error', 'error');return;
    //   }
    //   this.Buscar();

    // }, error => {
    //   $.LoadingOverlay("hide", true);
    // });
    
    let errorImportacion = () => {
      $('#progress-importacion-datos').css('width', '0%');
      $('.progress').hide();
      $.buttoncancelloading('btnimportacion');
      toastr.error('Se ha producido un error importando datos', 'Error!');
      $.importandolicitaciones = false;
      this.Buscar();
    };
    
    $.buttonloading('btnimportacion', 'Importando');
    $('#progress-importacion-datos').css('width', '0%');
    $('.progress').show();
    let max = 4;
    let step = 0;
    let stepPorcent = 100/4;
    $.importandolicitaciones = true;

    let ImportAnteriores = (num:number, final:boolean, callback:any) => {
      this.globals.Api('/importar-licitaciones-anteriores/FASJ98-3432QWEWQd'+(num ? '/'+num : '')).subscribe(data => {
        if (!data || data.error) {
          errorImportacion();return;
        }
        if (final) {
          $.importandolicitaciones = false;
          $('#progress-importacion-datos').css('width', '100%');
          $.buttoncancelloading('btnimportacion');
          setTimeout(() => {
            $('#progress-importacion-datos').css('width', '0%');
            $('.progress').hide();
          }, 1000);
          toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
          return;
        }
        step++;
        $('#progress-importacion-datos').css('width', (stepPorcent*step)+'%');
        if (callback) callback();
      }, error => {
        errorImportacion();
      });
    };
    
    let lasturl = null;
    let ImportarLicitaciones = (url:string = null) => {
      $.buttonloading('btnimportacion', 'Importando');
      $('.progress').show();
      this.globals.Api('/importar-licitaciones/FASJ98-3432QWEWQd', {url: url}).subscribe(data => {
        if (!data || data.error) {
          errorImportacion();return;
        }
        // console.log(data.fecha);
        if (step >= (max-1)) data.url = null;
        if (data.url && data.url != '' && data.url != lasturl) {
          lasturl = data.url;
          step++;
          $('#progress-importacion-datos').css('width', (stepPorcent*step)+'%');
          ImportarLicitaciones(data.url);return;
        }
        $.importandolicitaciones = false;
        $('#progress-importacion-datos').css('width', '100%');
        $('#btnimportacion').html(`<i class="fa fa-download"></i> Importar`);
        $('#btnimportacion').removeAttr('disabled');
        $('#btnimportacion').css('pointer-events', 'all');
        $('#btnimportacion').css('cursor', 'pointer');
        setTimeout(() => {
          $('#progress-importacion-datos').css('width', '0%');
          $('.progress').hide();
        }, 1000);
        toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
        this.Buscar();
        // this.globals.Api('/email-enviar-ultimas-licitaciones').subscribe();
      }, error => {
        errorImportacion();
      });
    };
    ImportarLicitaciones();
    // ImportAnteriores(3, false, () => {
    //   $.buttonloading('btnimportacion', 'Importando');
    //   $('.progress').show();
    //   ImportAnteriores(2, false, () => {
    //     $.buttonloading('btnimportacion', 'Importando');
    //     $('.progress').show();
    //     ImportAnteriores(1, false, () => {
    //       $.buttonloading('btnimportacion', 'Importando');
    //       $('.progress').show();
    //       ImportAnteriores(null, false, () => {
    //         $.buttonloading('btnimportacion', 'Importando');
    //         $('.progress').show();
    //         this.globals.Api('/importar-licitaciones/FASJ98-3432QWEWQd').subscribe(data => {
    //           console.log(data);
    //           if (!data || data.error) {
    //             errorImportacion();return;
    //           }
    //           $.importandolicitaciones = false;
    //           $('#progress-importacion-datos').css('width', '100%');
    //           $('#btnimportacion').html(`<i class="fa fa-download"></i> Importar`);
    //           setTimeout(() => {
    //             $('#progress-importacion-datos').css('width', '0%');
    //             $('.progress').hide();
    //           }, 1000);
    //           toastr.success(`Datos importados correctamente`, 'Importacion finalizada!');
    //           this.Buscar();
    //         }, error => {
    //           errorImportacion();
    //         });
    //       });
    //     });
    //   });
    // });
  }
}
