<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <div id="page-container" class="header-fixed-top">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-header></app-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="container p-b-20">
                    <!-- <div class="post-container">
                        <div class="post-sizer col-md-3 col-sm-6 col-xs-12"></div>
                        <div class="post-box col-md-3 col-sm-6 col-xs-12" *ngFor="let item of adjudicaciones" (click)="Licitacion(item)">
                            <div class="post-box-body">
                                <h2>{{item.num_expediente}}</h2>
                                <p>{{item.objeto_contrato}}</p>
                                <div class="row">
                                    <div class="col-sm-6 col-xs-12">
                                        <div class="mini-titulo">Importe</div>
                                        <b class="importe">{{item.presupuesto_base_sinimpuestos_format}}€</b>
                                    </div>
                                    <div class="col-sm-6 col-xs-12">
                                        <div class="mini-titulo">Ubicación</div>
                                        <b>{{item.provincia}}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div> -->
                    <h1 class="font-20 font-bold text-center m-b-40" style="color:#072861">Busca aquí la adjudicación</h1>
                    <app-buscador tipo="adjudicaciones"></app-buscador>
                    <h1 class="font-20 font-bold text-center  m-t-40 m-b-40" style="color:#072861"><i class="icofont-medal"></i> Ranking por importe</h1>
                    <div class="block text-center" style="padding: 2rem 1rem" *ngIf="!adjudicaciones_importe || !adjudicaciones_importe.length">
                        <h4>Cargando datos ...</h4>
                    </div>
                    <div class="swiper">
                        <div class="swiper-wrapper" *ngIf="adjudicaciones_importe && adjudicaciones_importe.length">
                            <ng-container *ngFor="let item of adjudicaciones_importe;let i = index">
                                <div class="swiper-slide">
                                    <div class="post-box">
                                        <a class="post-box-body" [routerLink]="['/licitacion/id:'+item.id]">
                                            <h2><i class="icofont-medal text-primary" *ngIf="i < 3"></i> {{item.num_expediente}}</h2>
                                            <p>{{item.objeto_contrato}}</p>
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="mini-titulo">Importe</div>
                                                    <b class="importe">{{item.presupuesto_base_sinimpuestos_format}}€</b>
                                                </div>
                                                <div class="col-sm-6 col-xs-12">
                                                    <div class="mini-titulo">Ubicación</div>
                                                    <b>{{item.provincia}}</b>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <div class="swiper-pagination"></div> -->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                    <h1 class="font-20 font-bold text-center m-t-40 m-b-40" style="color:#072861"><i class="icofont-medal"></i> Ranking por organismo</h1>
                    <div class="block text-center" style="padding: 2rem 1rem" *ngIf="!adjudicaciones_empresa || !adjudicaciones_empresa.length">
                        <h4>Cargando datos ...</h4>
                    </div>
                    <div class="swiper">
                        <div class="swiper-wrapper" *ngIf="adjudicaciones_empresa && adjudicaciones_empresa.length">
                            <ng-container *ngFor="let item of adjudicaciones_empresa;let i = index">
                                <div class="swiper-slide">
                                    <div class="post-box">
                                        <a class="post-box-body" [routerLink]="['/organismo/id:'+item.id]">
                                            <div class="text-center dato-grande">
                                                <h2><i class="icofont-medal text-primary" *ngIf="i < 3"></i> {{item.empresa}}</h2>
                                                <div class="mini-titulo">Total licitaciones</div>
                                                <b class="importe">{{item.total}}</b>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <div class="swiper-pagination"></div> -->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                    <h1 class="font-20 font-bold text-center m-t-40 m-b-40" style="color:#072861"><i class="icofont-medal"></i> Ranking de baja por empresa</h1>
                    <div class="block text-center" style="padding: 2rem 1rem" *ngIf="!adjudicaciones_baja || !adjudicaciones_baja.length">
                        <h4>Cargando datos ...</h4>
                    </div>
                    <div class="swiper m-b-100">
                        <div class="swiper-wrapper" *ngIf="adjudicaciones_baja && adjudicaciones_baja.length">
                            <ng-container *ngFor="let item of adjudicaciones_baja;let i = index">
                                <div class="swiper-slide">
                                    <div class="post-box">
                                        <a class="post-box-body" [routerLink]="['/adjudicatario/nombre:'+item.lote_adjudicatario]">
                                            <div class="text-center dato-grande">
                                                <h2><i class="icofont-medal text-primary" *ngIf="i < 3"></i> {{item.lote_adjudicatario}}</h2>
                                                <div class="mini-titulo">Baja</div>
                                                <b class="importe">{{item.baja}}%</b>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <div class="swiper-pagination"></div> -->
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
        <app-footer></app-footer>
    </div>
    <!-- END Page Container -->
</div>