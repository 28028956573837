<div class="modal inmodal fade" id="modal-licitacion" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg" style="max-width:1200px;">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
            <div class="pull-right m-r-30 m-t--30 mobile-hidden" *ngIf="data.num_expediente && !licitaciones.length">
              <a (click)="Editar()" class="btn btn-default btn-sm m-r-10"><i class="fa fa-pencil"></i> Editar</a>
              <a (click)="Borrar()" class="btn btn-default btn-sm"><i class="fa fa-trash"></i> Borrar</a>
            </div>
        </div>
        <div class="modal-body p-t-0">
          <ng-container *ngIf="data.num_expediente && !licitaciones.length">
            <ul class="nav nav-pills" data-toggle="tabs">
                <li class="active"><a href="#tab-modal-general">Datos generales</a></li>
                <li class="{{data.ganador && data.ganador.length ? null : 'hidden'}}"><a href="#tab-modal-ganadores">Ganadores</a></li>
                <li><a href="#tab-modal-documentacion"><i class="icofont-file-pdf"></i> Documentación</a></li>
                <li class="{{data.cambios && data.cambios.length ? null : 'hidden'}}"><a href="#tab-modal-cambios"><i class="icofont-history"></i> Histórico</a></li>
                <li class="{{data.datos_origen && data.datos_origen.length ? null : 'hidden'}}"><a href="#tab-origen-datos"><i class="icofont-database"></i> Origen datos</a></li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab-modal-general">
                <div class="row">
                  <div class="col-md-12 col-xs-12">
                    
                    <h2 class="m-b-10 font-16">Expediente: {{data.num_expediente}}</h2>
                    <table class="table table-bordered table-vcenter table-licitacion">
                      <tr><td class="table-licitacion-bg">Últ. Actualización</td><td>{{data.fecha_actualizacion_format}}</td></tr>
                      <tr><td class="table-licitacion-bg">Órgano de contratación</td><td>{{data.organo_contratacion}}</td></tr>
                      <tr><td class="table-licitacion-bg">Estado de la licitación</td><td>{{data.estado}}</td></tr>
                      <tr><td class="table-licitacion-bg">Objeto del contrato</td><td>{{data.objeto_contrato}}</td></tr>
                      <tr><td class="table-licitacion-bg">Presupuesto base de licitación sin impuestos</td><td>{{data.presupuesto_base_sinimpuestos_format}}€</td></tr>
                      <tr><td class="table-licitacion-bg">Valor estimado del contrato</td><td>{{data.valor_estimado_format}}€</td></tr>
                      <tr><td class="table-licitacion-bg">Tipo de contrato</td><td>{{data.tipo_contrato}}</td></tr>
                      <tr><td class="table-licitacion-bg">Código CPV</td><td>{{data.cpv}}</td></tr>
                      <tr><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{data.poblacion}} - {{data.provincia}}</td></tr>
                      <tr><td class="table-licitacion-bg">Procedimiento de contratación</td><td>{{data.tipo_procedimiento}}</td></tr>
                    </table>
                    <ng-container *ngIf="data.fecha_fin_format">
                      <hr style="margin: 10px 0;margin-top: -10px;">
                      <table class="table table-bordered table-vcenter table-licitacion">
                        <tr><td class="table-licitacion-bg">Fecha fin de presentación de oferta</td><td>{{data.fecha_fin_format}}</td></tr>
                      </table>
                    </ng-container>
                    <ng-container *ngFor="let item of data.lotes">
                      <hr style="margin: 10px 0;margin-top: -10px;">
                      <div class="relative">
                        <table class="table table-bordered table-vcenter table-licitacion">
                          <tr *ngIf="item.resultado"><td class="table-licitacion-bg">Resultado</td><td>{{item.resultado}}</td></tr>
                          <tr *ngIf="item.objeto_contrato"><td class="table-licitacion-bg">Lote</td><td>{{item.objeto_contrato}}</td></tr>
                          <tr *ngIf="item.num_lote"><td class="table-licitacion-bg">Número de lote</td><td>{{item.num_lote}}</td></tr>
                          <tr *ngIf="item.lote_adjudicatario"><td class="table-licitacion-bg">Adjudicatario</td><td>{{item.lote_adjudicatario}}</td></tr>
                          <tr *ngIf="item.num_licitadores_presentados"><td class="table-licitacion-bg">Nº de Licitadores Presentados</td><td>{{item.num_licitadores_presentados}}</td></tr>
                          <tr *ngIf="item.lote_importe_sinimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación sin impuestos</td><td>{{item.lote_importe_sinimpuestos_format}}€</td></tr>
                          <tr *ngIf="item.lote_importe_conimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación con impuestos</td><td>{{item.lote_importe_conimpuestos_format}}€</td></tr>
                          <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                          <tr *ngIf="item.lote_lugar_ejecucion"><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{item.lote_lugar_ejecucion}}</td></tr>
                        </table>
                        <!-- <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;opacity:.5;" (click)="DelLote(item)"><i class="fa fa-trash"></i></a> -->
                      </div>
                    </ng-container>
                    <hr style="margin: 10px 0;margin-top: -10px;">
                    <a href="{{data.url}}" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{data.url}}</a>
                    <br>
                    <a href="{{data.url_atom}}" target="_blank" style="word-break:break-all;" *ngIf="data.url_atom"><i class="fa fa-external-link"></i> Atom: {{data.url_atom}}</a>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-modal-ganadores">
                <ng-container *ngIf="data.ganador && data.ganador.length">
                  <h2 class="m-b-10 font-16">Ganadores</h2>
                </ng-container>
                <ng-container *ngFor="let item of data.ganador">
                  <hr style="margin: 10px 0;margin-top: -10px;">
                  <div class="relative">
                    <table class="table table-bordered table-vcenter table-licitacion">
                      <tr *ngIf="item.resultado"><td class="table-licitacion-bg">Resultado</td><td>{{item.resultado}}</td></tr>
                      <tr *ngIf="item.num_lote"><td class="table-licitacion-bg">Número de lote</td><td>{{item.num_lote}}</td></tr>
                      <tr *ngIf="item.num_contrato"><td class="table-licitacion-bg">Número de contrato</td><td>{{item.num_contrato}}</td></tr>
                      <tr *ngIf="item.lote_adjudicatario"><td class="table-licitacion-bg">Adjudicatario</td><td>{{item.lote_adjudicatario}}</td></tr>
                      <tr *ngIf="item.num_licitadores_presentados"><td class="table-licitacion-bg">Nº de Licitadores Presentados</td><td>{{item.num_licitadores_presentados}}</td></tr>
                      <tr *ngIf="item.lote_importe_sinimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación sin impuestos</td><td>{{item.lote_importe_sinimpuestos_format}}€</td></tr>
                      <tr *ngIf="item.lote_importe_conimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación con impuestos</td><td>{{item.lote_importe_conimpuestos_format}}€</td></tr>
                      <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                      <tr *ngIf="item.lote_lugar_ejecucion"><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{item.lote_lugar_ejecucion}}</td></tr>
                    </table>
                    <!-- <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;opacity:.5;" (click)="DelGanador(item)"><i class="fa fa-trash"></i></a> -->
                  </div>
                </ng-container>
              </div>
              <div class="tab-pane" id="tab-modal-documentacion">
                <h2 class="m-b-10 font-16">Documentos</h2>
                <table class="table table-bordered table-vcenter table-licitacion">
                  <tr *ngIf="data.pliego_prescripciones_tecnicas">
                    <td class="table-licitacion-bg">Pliego prescripciones técnicas</td>
                    <td><a [href]="data.pliego_prescripciones_tecnicas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego prescripciones técnicas.pdf<!--{{data.id_pliego_prescripciones_tecnicas}}--></a></td>
                  </tr>
                  <tr *ngIf="data.pliego_clausulas_administrativas">
                    <td class="table-licitacion-bg">Pliego cláusulas administrativas</td>
                    <td><a [href]="data.pliego_clausulas_administrativas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego cláusulas administrativas.pdf<!--{{data.id_pliego_clausulas_administrativas}}--></a></td>
                  </tr>
                  <tr *ngIf="data.anexos_pliegos">
                    <td class="table-licitacion-bg">Anexos pliegos</td>
                    <td><a [href]="data.anexos_pliegos" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{data.id_anexos_pliegos}}</a></td>
                  </tr>
                </table>
                <ng-container *ngIf="data.documentos && data.documentos.length">
                  <h2 class="m-b-10 font-16">Documentos adicionales</h2>
                  <table class="table table-bordered table-vcenter table-licitacion">
                    <tr *ngFor="let item of data.documentos">
                      <td class="table-licitacion-bg">Documento PDF</td>
                      <td><a [href]="item.url" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{item.nombre}}</a></td>
                    </tr>
                  </table>
                </ng-container>
              </div>
              <div class="tab-pane" id="tab-modal-cambios">
                <h2 class="m-b-10 font-16">Histórico de cambios</h2>
                
                <div class="timeline block-content-full">
                  <ul class="timeline-list" *ngIf="data.cambios && data.cambios.length">
                    <li *ngFor="let item of data.cambios">
                        <div class="timeline-time">{{globals.PostedDate(item.alta)}}</div>
                        <div class="timeline-icon themed-background-info text-light-op"><i class="icofont-history"></i></div>
                        <div class="timeline-content">
                          <div class="cambios-licitacion" [innerHTML]="item.cambios"></div>
                        </div>
                    </li>
                  </ul>
                </div>
  
              </div>
              <div class="tab-pane" id="tab-origen-datos">
                
                <ng-container *ngIf="data.datos_origen && data.datos_origen.length">
                  <div class="row">
                    <div class="col-md-3 col-sm-12">
                      <ul class="nav nav-pills nav-stacked" data-toggle="tabs" style="max-height:488px;overflow:auto;">
                        <li class="{{i == 0 ? 'active' : null}}" *ngFor="let item of data.datos_origen;let i = index">
                          <a href="#tab-origen-datos-{{i}}" data-toggle="tab" (click)="SelectOrigenDatos(item, i)">{{item.fecha_actualizacion_format}}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-9 col-sm-12">
                      <div class="tab-content">
                        <ng-container *ngFor="let item of data.datos_origen;let i = index">
                          <div class="tab-pane tab-origen-datos {{i == 0 ? 'active' : null}} p-t-10 p-b-10" id="tab-origen-datos-{{i}}">
                            <a href="{{data.url_atom}}" target="_blank" style="word-break:break-all;" *ngIf="item.url_atom"><i class="fa fa-external-link"></i> {{item.url_atom}}</a>
                            <div id="jstree-{{i}}" class="m-t-20"></div>
                            <textarea id="txt-origen-datos-{{i}}" class="form-control m-t-20" rows="10"></textarea>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>
  
            <div class="text-center m-t-20 desktop-hidden">
              <a (click)="Editar()" class="btn btn-default btn-sm m-r-10"><i class="fa fa-pencil"></i> Editar</a>
              <a (click)="Borrar()" class="btn btn-default btn-sm"><i class="fa fa-trash"></i> Borrar</a>
            </div>
          </ng-container>
          <ng-container *ngIf="licitaciones.length">
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <ul class="nav nav-pills nav-stacked" data-toggle="tabs" style="max-height:488px;overflow:auto;">
                  <li class="{{i == 0 ? 'active' : null}}" *ngFor="let item of licitaciones;let i = index">
                    <a href="#tab-licitacion-{{i}}" (click)="SelectLicitacion(item)">{{item.num_expediente}}</a>
                  </li>
                </ul>
              </div>
              <div class="col-md-9 col-sm-12">
                <ng-container *ngIf="selecteddata && selecteddata.num_expediente">
                  <ul class="nav nav-pills" data-toggle="tabs">
                    <li class="active"><a href="#tab-modal-general">Datos generales</a></li>
                    <li class="{{selecteddata.ganador && selecteddata.ganador.length ? null : 'hidden'}}"><a href="#tab-modal-ganadores">Ganadores</a></li>
                    <li><a href="#tab-modal-documentacion"><i class="icofont-file-pdf"></i> Documentación</a></li>
                    <li class="{{selecteddata.cambios && selecteddata.cambios.length ? null : 'hidden'}}"><a href="#tab-modal-cambios"><i class="icofont-history"></i> Histórico</a></li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tab-modal-general">
                      <div class="row">
                        <div class="col-md-12 col-xs-12">
                          
                          <h2 class="m-b-10 font-16">Expediente: {{selecteddata.num_expediente}}</h2>
                          <table class="table table-bordered table-vcenter table-licitacion">
                            <tr><td class="table-licitacion-bg">Últ. Actualización</td><td>{{selecteddata.fecha_actualizacion_format}}</td></tr>
                            <tr><td class="table-licitacion-bg">Órgano de contratación</td><td>{{selecteddata.organo_contratacion}}</td></tr>
                            <tr><td class="table-licitacion-bg">Estado de la licitación</td><td>{{selecteddata.estado}}</td></tr>
                            <tr><td class="table-licitacion-bg">Objeto del contrato</td><td>{{selecteddata.objeto_contrato}}</td></tr>
                            <tr><td class="table-licitacion-bg">Presupuesto base de licitación sin impuestos</td><td>{{selecteddata.presupuesto_base_sinimpuestos_format}}€</td></tr>
                            <tr><td class="table-licitacion-bg">Valor estimado del contrato</td><td>{{selecteddata.valor_estimado_format}}€</td></tr>
                            <tr><td class="table-licitacion-bg">Tipo de contrato</td><td>{{selecteddata.tipo_contrato}}</td></tr>
                            <tr><td class="table-licitacion-bg">Código CPV</td><td>{{selecteddata.cpv}}</td></tr>
                            <tr><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{selecteddata.poblacion}} - {{selecteddata.provincia}}</td></tr>
                            <tr><td class="table-licitacion-bg">Procedimiento de contratación</td><td>{{selecteddata.tipo_procedimiento}}</td></tr>
                          </table>
                          <ng-container *ngIf="selecteddata.fecha_fin_format">
                            <hr style="margin: 10px 0;margin-top: -10px;">
                            <table class="table table-bordered table-vcenter table-licitacion">
                              <tr><td class="table-licitacion-bg">Fecha fin de presentación de oferta</td><td>{{selecteddata.fecha_fin_format}}</td></tr>
                            </table>
                          </ng-container>
                          <ng-container *ngFor="let item of selecteddata.lotes">
                            <hr style="margin: 10px 0;margin-top: -10px;">
                            <div class="relative">
                              <table class="table table-bordered table-vcenter table-licitacion">
                                <tr *ngIf="item.resultado"><td class="table-licitacion-bg">Resultado</td><td>{{item.resultado}}</td></tr>
                                <tr *ngIf="item.num_lote"><td class="table-licitacion-bg">Número de lote</td><td>{{item.num_lote}}</td></tr>
                                <tr *ngIf="item.lote_adjudicatario"><td class="table-licitacion-bg">Adjudicatario</td><td>{{item.lote_adjudicatario}}</td></tr>
                                <tr *ngIf="item.num_licitadores_presentados"><td class="table-licitacion-bg">Nº de Licitadores Presentados</td><td>{{item.num_licitadores_presentados}}</td></tr>
                                <tr *ngIf="item.lote_importe_sinimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación sin impuestos</td><td>{{item.lote_importe_sinimpuestos_format}}€</td></tr>
                                <tr *ngIf="item.lote_importe_conimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación con impuestos</td><td>{{item.lote_importe_conimpuestos_format}}€</td></tr>
                                <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                                <tr *ngIf="item.lote_lugar_ejecucion"><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{item.lote_lugar_ejecucion}}</td></tr>
                              </table>
                              <!-- <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;opacity:.5;" (click)="DelLote(item)"><i class="fa fa-trash"></i></a> -->
                            </div>
                          </ng-container>
                          <hr style="margin: 10px 0;margin-top: -10px;">
                          <a href="{{selecteddata.url}}" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{selecteddata.url}}</a>
                          <br>
                          <a href="{{data.url_atom}}" target="_blank" style="word-break:break-all;" *ngIf="data.url_atom"><i class="fa fa-external-link"></i> Atom: {{data.url_atom}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="tab-modal-ganadores">
                      <ng-container *ngIf="selecteddata.ganador && selecteddata.ganador.length">
                        <h2 class="m-b-10 font-16">Ganadores</h2>
                      </ng-container>
                      <ng-container *ngFor="let item of selecteddata.ganador">
                        <hr style="margin: 10px 0;margin-top: -10px;">
                        <div class="relative">
                          <table class="table table-bordered table-vcenter table-licitacion">
                            <tr *ngIf="item.resultado"><td class="table-licitacion-bg">Resultado</td><td>{{item.resultado}}</td></tr>
                            <tr *ngIf="item.objeto_contrato"><td class="table-licitacion-bg">Lote</td><td>{{item.objeto_contrato}}</td></tr>
                            <tr *ngIf="item.num_lote"><td class="table-licitacion-bg">Número de lote</td><td>{{item.num_lote}}</td></tr>
                            <tr *ngIf="item.num_contrato"><td class="table-licitacion-bg">Número de contrato</td><td>{{item.num_contrato}}</td></tr>
                            <tr *ngIf="item.lote_adjudicatario"><td class="table-licitacion-bg">Adjudicatario</td><td>{{item.lote_adjudicatario}}</td></tr>
                            <tr *ngIf="item.num_licitadores_presentados"><td class="table-licitacion-bg">Nº de Licitadores Presentados</td><td>{{item.num_licitadores_presentados}}</td></tr>
                            <tr *ngIf="item.lote_importe_sinimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación sin impuestos</td><td>{{item.lote_importe_sinimpuestos_format}}€</td></tr>
                            <tr *ngIf="item.lote_importe_conimpuestos"><td class="table-licitacion-bg">Importe de Adjudicación con impuestos</td><td>{{item.lote_importe_conimpuestos_format}}€</td></tr>
                            <tr *ngIf="item.baja"><td class="table-licitacion-bg">Baja</td><td>{{item.baja}}%</td></tr>
                            <tr *ngIf="item.lote_lugar_ejecucion"><td class="table-licitacion-bg">Lugar de ejecución</td><td>{{item.lote_lugar_ejecucion}}</td></tr>
                          </table>
                          <!-- <a class="btn btn-danger btn-xs absolute" style="top:0;right:0;opacity:.5;" (click)="DelGanador(item)"><i class="fa fa-trash"></i></a> -->
                        </div>
                      </ng-container>
                    </div>
                    <div class="tab-pane" id="tab-modal-documentacion">
                      <h2 class="m-b-10 font-16">Documentos</h2>
                      <table class="table table-bordered table-vcenter table-licitacion">
                        <tr *ngIf="selecteddata.pliego_prescripciones_tecnicas">
                          <td class="table-licitacion-bg">Pliego prescripciones técnicas</td>
                          <td><a [href]="selecteddata.pliego_prescripciones_tecnicas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego prescripciones técnicas.pdf<!--{{selecteddata.id_pliego_prescripciones_tecnicas}}--></a></td>
                        </tr>
                        <tr *ngIf="selecteddata.pliego_clausulas_administrativas">
                          <td class="table-licitacion-bg">Pliego cláusulas administrativas</td>
                          <td><a [href]="selecteddata.pliego_clausulas_administrativas" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> Pliego cláusulas administrativas.pdf<!--{{selecteddata.id_pliego_clausulas_administrativas}}--></a></td>
                        </tr>
                        <tr *ngIf="selecteddata.anexos_pliegos">
                          <td class="table-licitacion-bg">Anexos pliegos</td>
                          <td><a [href]="selecteddata.anexos_pliegos" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{selecteddata.id_anexos_pliegos}}</a></td>
                        </tr>
                      </table>
                      <ng-container *ngIf="selecteddata.documentos && selecteddata.documentos.length">
                        <h2 class="m-b-10 font-16">Documentos adicionales</h2>
                        <table class="table table-bordered table-vcenter table-licitacion">
                          <tr *ngFor="let item of selecteddata.documentos">
                            <td class="table-licitacion-bg">Documento PDF</td>
                            <td><a [href]="item.url" target="_blank" style="word-break:break-all;"><i class="fa fa-external-link"></i> {{item.nombre}}</a></td>
                          </tr>
                        </table>
                      </ng-container>
                    </div>
                    <div class="tab-pane" id="tab-modal-cambios">
                      <h2 class="m-b-10 font-16">Histórico de cambios</h2>
                      
                      <div class="timeline block-content-full">
                        <ul class="timeline-list" *ngIf="selecteddata.cambios && selecteddata.cambios.length">
                          <li *ngFor="let item of selecteddata.cambios">
                              <div class="timeline-time">{{globals.PostedDate(item.alta)}}</div>
                              <div class="timeline-icon themed-background-info text-light-op"><i class="icofont-history"></i></div>
                              <div class="timeline-content">
                                <div class="cambios-licitacion" [innerHTML]="item.cambios"></div>
                              </div>
                          </li>
                        </ul>
                      </div>
        
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cerrar</button>
        </div>
        </div>
    </div>
</div>