import { ApplicationRef, Component, NgZone, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html'
})
export class BuscadorComponent implements OnInit {
  @Input() tipo: string = '';
  public licitaciones:any = [];
  public licitacionesId:any = [];
  public busqueda:string = '';
  public EstadosLicitaciones:any = [];
  public LocalidadesLicitaciones:any = [];
  public NombresLicitaciones:any = [];
  public ExpedienteLicitaciones:any = [];
  public OrganismosLicitaciones:any = [];
  public AdjudicatariosLicitaciones:any = [];
  public AdjudicatariosLista:any = [];
  public filtros:any = {
    nombre:     '',
    estado:     '',
    localidad:  '',
    expediente: '',
    busqueda:   '',
    orden:      ''
  };
  public contLicitaciones:any = 0;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals) {
  }

  ngOnInit(): void {
    if(this.tipo == 'dashboard'){
      this.CargarLicitaciones();
    }
    if(this.tipo == 'mis-obras'){
      this.CargarMisLicitacionesId();
    }
    let that = this;
    window.onclick = function(e:any) {   
      if (!document.querySelector('#content-search') || !document.querySelector('#filter-search')) {
        $('#content-search').hide();
        return;
      }
      if (document.querySelector('#content-search').contains(e.target) || document.querySelector('#filter-search').contains(e.target)) {
        // Clicked in box
      } else{
        // Clicked outside the box
        $('#content-search').hide();
      }
    };
  }
  CargarLicitaciones() {
    if (this.globals.GetData('ultimas-licitaciones')) this.licitaciones = this.globals.GetData('ultimas-licitaciones');
    this.globals.Api('/licitaciones', {page_index: 1, page_size: 5, estado: 'EN PLAZO (PUB)'}).subscribe(data => {
      if (!data || !data.length) return;
      this.licitaciones = data;
      this.globals.SaveData(data, 'ultimas-licitaciones');
    });
  }

  Buscar(Event:any){
    if (Event.keyCode == 13) return;
    if (this.busqueda.length < 4) {
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      this.OrganismosLicitaciones = [];
      this.AdjudicatariosLicitaciones = [];
      this.AdjudicatariosLista = [];
      this.filtros.busqueda = '';
      $('#content-loading').hide();
      if (this.tipo == 'adjudicaciones') this.licitaciones = [];
      return;
    }
    clearInterval(globalThis.idtime);
    globalThis.idtime = setTimeout(() => {
      $('#content-loading').show();
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      this.OrganismosLicitaciones = [];
      this.AdjudicatariosLicitaciones = [];
      this.AdjudicatariosLista = [];
      
      if (this.busqueda.length < 3) {
        $('#content-search').show();
        if(this.tipo == 'dashboard'){
          this.BuscarDashboard();
        }
        if(this.tipo == 'mis-obras'){
          this.BuscarMisLicitaciones();
        }
        if(this.tipo == 'adjudicaciones'){
          this.BuscarAdjudicaciones();
        }
      } else {
        $('#content-loading').hide();
        if (this.tipo == 'adjudicaciones') this.licitaciones = [];
      }
    }, 200);
  }
  BuscarDashboard(){
    this.globals.Api('/licitaciones-busqueda', { filtro: this.busqueda, modalbuscador: true }).subscribe(data => {
      $('#content-loading').hide();
      //this.NombresLicitaciones = [];
      // if (!data || !data.length) return;
      //this.licitaciones = data;
      //this.globals.SaveData(data, 'ultimas-licitaciones');
      
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      this.OrganismosLicitaciones = [];
      this.AdjudicatariosLicitaciones = [];

      //CAMBIO DANI - BUSQUEDA POR OBJETOS
      if (!data) return;
      if (data.generales && data.generales.length) {
        this.contLicitaciones = data.generales.length;
        data.generales.forEach(licitacion => {
          //console.log(licitacion.objeto_contrato.search(this.busqueda));
          let tempNombre = licitacion.objeto_contrato.toLowerCase();
          tempNombre = this.globals.CleanedString(tempNombre);
          //console.log(tempNombre);
          //this.NombresLicitaciones.push(licitacion.objeto_contrato);
          if(tempNombre.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
            //   this.EstadosLicitaciones.push(licitacion.estado);
            // }
            // if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
            //   this.LocalidadesLicitaciones.push(licitacion.poblacion);
            // }
            // if(this.OrganismosLicitaciones.indexOf(licitacion.organo_contratacion) === -1 && this.globals.CleanedString(licitacion.organo_contratacion).indexOf(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            //   this.OrganismosLicitaciones.push(licitacion.organo_contratacion);
            // }
            // if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
            //   this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            // }
            return;
          }
          return;
          let tempEstados = licitacion.estado.toLowerCase();
          tempEstados = this.globals.CleanedString(tempEstados);
          if(tempEstados.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.OrganismosLicitaciones.indexOf(licitacion.organo_contratacion) === -1 && this.globals.CleanedString(licitacion.organo_contratacion).indexOf(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
              this.OrganismosLicitaciones.push(licitacion.organo_contratacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempLocalidades = licitacion.poblacion.toLowerCase();
          tempLocalidades = this.globals.CleanedString(tempLocalidades);
          if(tempLocalidades.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.OrganismosLicitaciones.indexOf(licitacion.organo_contratacion) === -1 && this.globals.CleanedString(licitacion.organo_contratacion).indexOf(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
              this.OrganismosLicitaciones.push(licitacion.organo_contratacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempOrganismos = licitacion.organo_contratacion.toLowerCase();
          tempOrganismos = this.globals.CleanedString(tempOrganismos);
          if(tempOrganismos.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.OrganismosLicitaciones.indexOf(licitacion.organo_contratacion) === -1 && this.globals.CleanedString(licitacion.organo_contratacion).indexOf(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
              this.OrganismosLicitaciones.push(licitacion.organo_contratacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempExpedientes = licitacion.num_expediente.toLowerCase();
          tempExpedientes = this.globals.CleanedString(tempExpedientes);
          if(tempExpedientes.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.OrganismosLicitaciones.indexOf(licitacion.organo_contratacion) === -1 && this.globals.CleanedString(licitacion.organo_contratacion).indexOf(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
              this.OrganismosLicitaciones.push(licitacion.organo_contratacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
        });
      }
      
      //ESTADOS
      if (data.estados && data.estados.length) {
        for (let item of data.estados) this.EstadosLicitaciones.push(item.estado);
      }
      //LOCALIDADES
      if (data.localidades && data.localidades.length) {
        for (let item of data.localidades) this.LocalidadesLicitaciones.push(item.localidad);
      }
      //EXPEDIENTES
      if (data.expedientes && data.expedientes.length) {
        for (let item of data.expedientes) this.ExpedienteLicitaciones.push([item.expediente, item.id]);
      }
      //ORGANISMOS
      if (data.organismos && data.organismos.length) {
        for (let item of data.organismos) this.OrganismosLicitaciones.push(item.organismo);
      }
      //ADJUDICATARIOS
      if (data.adjudicatarios && data.adjudicatarios.length) {
        for (let item of data.adjudicatarios) this.AdjudicatariosLicitaciones.push(item.adjudicatario);
      }
      //this.appref.tick();
    });
  }
  AplicarBusqueda(localidad:any,estado:any,orden:any){
    $('#content-loading-2').show();
    this.filtros.localidad = localidad;
    this.filtros.estado = estado;
    this.filtros.orden = orden;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  AplicarBusquedaLocalidad(localidad:any){
    $('#content-loading-2').show();
    this.filtros.localidad = localidad;
    this.filtros.estado = 'En plazo (PUB)';
    this.EjecutarQueryFiltersOrdersDashboard();
  }
  MostrarFiltroEstado(){
    $('#content-orden-selector').hide();
    $('#content-estados-selector').toggle();
  }
  MostrarFiltroOrden(){
    $('#content-estados-selector').hide();
    $('#content-orden-selector').toggle();
  }
  ResetBusqueda() {
    if ( this.filtros.localidad == ''
      && this.filtros.estado == ''
      && this.filtros.orden == ''
      && this.busqueda == ''
      ) {
      $('#content-loading-2').hide();
      $('#no-resultados').hide();
      $('#filter-search').val('');
      $('#title-content-licitaciones-cards').text('');
      if (this.tipo == 'dashboard') {
        this.CargarLicitaciones();
        this.MostrarMapa();
      }
      if (this.tipo == 'mis-obras') {
        this.licitaciones = [];
        this.NombresLicitaciones = [];
        this.EstadosLicitaciones = [];
        this.LocalidadesLicitaciones = [];
        this.ExpedienteLicitaciones = [];
        this.OrganismosLicitaciones = [];
        this.AdjudicatariosLicitaciones = [];
        this.AdjudicatariosLista = [];
        this.CargarMisLicitacionesId();
      }
      if (this.tipo == 'adjudicaciones') {
        this.licitaciones = [];
        this.NombresLicitaciones = [];
        this.EstadosLicitaciones = [];
        this.LocalidadesLicitaciones = [];
        this.ExpedienteLicitaciones = [];
        this.OrganismosLicitaciones = [];
        this.AdjudicatariosLicitaciones = [];
        this.AdjudicatariosLista = [];
      }
      return false;
    }
    return true;
  }
  ClearFiltroEstado(){
    $('#content-loading-2').show();
    this.filtros.estado = '';
    if (!this.ResetBusqueda()) return;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  ClearFiltroLocalidad() {
    $('#content-loading-2').show();
    this.filtros.localidad = '';
    if (!this.ResetBusqueda()) return;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  ClearFiltroBusqueda(){
    $('#content-loading-2').show();
    this.filtros.busqueda = '';
    this.busqueda = '';
    if (!this.ResetBusqueda()) return;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  ClearFiltroOrden(){
    $('#content-loading-2').show();
    this.filtros.orden = '';
    if (!this.ResetBusqueda()) return;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  AutorrellenarTodosResutados(){
    if (!this.busqueda || !this.busqueda.length || this.busqueda == '') return;
    if (this.busqueda.length < 4) return;
    $('#content-search').hide();
    this.filtros.busqueda = this.busqueda;
    if(this.tipo == 'dashboard'){
      this.EjecutarQueryFiltersOrdersDashboard();
      this.BusquedaFullWidth();
    }
    if(this.tipo == 'mis-obras'){
      this.EjecutarQueryFiltersOrdersMisObras();
    }
    if(this.tipo == 'adjudicaciones'){
      this.EjecutarQueryFiltersOrdersAdjudicaciones();
    }
  }
  EjecutarQueryFiltersOrdersDashboard(){
    this.globals.Api('/licitaciones-busqueda', {localidad: this.filtros.localidad,estado: this.filtros.estado,filtro: this.busqueda,orden: this.filtros.orden}).subscribe(data => {
      $('#content-loading-2').hide();
      if (!data || !data.length){
        this.licitaciones = [];
        $('#no-resultados').show();
        $('#filter-search').val('');
        this.NombresLicitaciones = [];
        this.EstadosLicitaciones = [];
        this.LocalidadesLicitaciones = [];
        this.ExpedienteLicitaciones = [];
        return;
      } 
      this.licitaciones = data;
      this.licitaciones.forEach(element => {
        element.presupuesto_base_conimpuestos = new Intl.NumberFormat().format(element.presupuesto_base_conimpuestos);
      });
      //this.globals.SaveData(data, 'licitaciones-filtradas');
      this.appref.tick();
      $('#filter-search').val('');
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      $('#title-content-licitaciones-cards').text('Filtrando por:');
      $('#no-resultados').hide();
    });
  }
  MostrarComboBuscar() {
    if (this.busqueda.length) {
      $('#content-search').show();
    }
  }
  OcutarComboBuscar() {
    setTimeout(() => {
      $('#content-search').hide();
    },100);
  }

  /*------Mis licitaciones-------*/

  CargarMisLicitacionesId(){
    //let dataUser = JSON.parse(localStorage.getItem('userdata'));
    //this.globals.Api('/licitaciones-seguidas', {id_usuario: dataUser.id}).subscribe(data => {
    this.globals.Api('/licitaciones-seguidas-id').subscribe(data => {
      if (!data) return ;
      this.licitacionesId = data;
      this.CargarMisLicitaciones(data);
    });
  }
  CargarMisLicitaciones(data:any){
    this.globals.Api('/licitaciones-seguidas',{licitacionesId: data}).subscribe(data => {
      if (!data) return ;
      this.licitaciones = data;
      this.globals.SaveData(data, 'mis-licitaciones');
    });
  }
  BuscarMisLicitaciones(){
    this.globals.Api('/licitaciones-busqueda-mis-licitaciones', {
      licitacionesId: this.licitacionesId, 
      filtro: this.busqueda,
      modalbuscador: true
    }).subscribe(data => {
      $('#content-loading').hide();
      //this.NombresLicitaciones = [];
      // if (!data || !data.length) return;
      //this.licitaciones = data;
      //this.globals.SaveData(data, 'ultimas-licitaciones');
      
      
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      this.OrganismosLicitaciones = [];
      this.AdjudicatariosLicitaciones = [];

      //CAMBIO DANI - BUSQUEDA POR OBJETOS
      if (!data) return;
      if (data.generales && data.generales.length) {
        this.contLicitaciones = data.generales.length;
        data.generales.forEach(licitacion => {
          //console.log(licitacion.objeto_contrato.search(this.busqueda));
          let tempNombre = licitacion.objeto_contrato.toLowerCase();
          tempNombre = this.globals.CleanedString(tempNombre);
          //console.log(tempNombre);
          //this.NombresLicitaciones.push(licitacion.objeto_contrato);
          if(tempNombre.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
            //   this.EstadosLicitaciones.push(licitacion.estado);
            // }
            // if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
            //   this.LocalidadesLicitaciones.push(licitacion.poblacion);
            // }
            // if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
            //   this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            // }
            return;
          }
          return;
          let tempEstados = licitacion.estado.toLowerCase();
          tempEstados = this.globals.CleanedString(tempEstados);
          if(tempEstados.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempLocalidades = licitacion.poblacion.toLowerCase();
          tempLocalidades = this.globals.CleanedString(tempLocalidades);
          if(tempLocalidades.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempExpedientes = licitacion.num_expediente.toLowerCase();
          tempExpedientes = this.globals.CleanedString(tempExpedientes);
          if(tempExpedientes.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
              this.EstadosLicitaciones.push(licitacion.estado);
            }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
        });
      }

      //ESTADOS
      if (data.estados && data.estados.length) {
        for (let item of data.estados) this.EstadosLicitaciones.push(item.estado);
      }
      //LOCALIDADES
      if (data.localidades && data.localidades.length) {
        for (let item of data.localidades) this.LocalidadesLicitaciones.push(item.localidad);
      }
      //EXPEDIENTES
      if (data.expedientes && data.expedientes.length) {
        for (let item of data.expedientes) this.ExpedienteLicitaciones.push([item.expediente, item.id]);
      }
      //ORGANISMOS
      if (data.organismos && data.organismos.length) {
        for (let item of data.organismos) this.OrganismosLicitaciones.push(item.organismo);
      }
      //ADJUDICATARIOS
      if (data.adjudicatarios && data.adjudicatarios.length) {
        for (let item of data.adjudicatarios) this.AdjudicatariosLicitaciones.push(item.adjudicatario);
      }
      //this.appref.tick();
    });
  }
  EjecutarQueryFiltersOrdersMisObras(){
    this.globals.Api('/licitaciones-busqueda-mis-licitaciones', {licitacionesId: this.licitacionesId, localidad: this.filtros.localidad,estado: this.filtros.estado,filtro: this.busqueda,orden: this.filtros.orden}).subscribe(data => {
      $('#content-loading-2').hide();
      if (!data || !data.length){
        this.licitaciones = [];
        $('#no-resultados').show();
        $('#filter-search').val('');
        this.NombresLicitaciones = [];
        this.EstadosLicitaciones = [];
        this.LocalidadesLicitaciones = [];
        this.ExpedienteLicitaciones = [];
        return;
      } 
      this.licitaciones = data;
      this.licitaciones.forEach(element => {
        element.presupuesto_base_conimpuestos = new Intl.NumberFormat().format(element.presupuesto_base_conimpuestos);
      });
      //this.globals.SaveData(data, 'licitaciones-filtradas');
      this.appref.tick();
      $('#filter-search').val('');
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      $('#title-content-licitaciones-cards').text('Filtrando por:');
      $('#no-resultados').hide();
    });
  }

  /*Adjudicaciones*/
  BuscarAdjudicaciones(){
    this.globals.Api('/licitaciones-busqueda', {
      filtro: this.busqueda, 
      estado: 'ADJUDICADA (ADJ)', 
      tipo: 'buscador-adjudicaciones',
      modalbuscador: true
    }).subscribe(data => {
      $('#content-loading').hide(); 
      // if (!data || !data.length) return;
      
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      this.OrganismosLicitaciones = [];
      this.AdjudicatariosLicitaciones = [];

      //CAMBIO DANI - BUSQUEDA POR OBJETOS
      if (!data) return;
      if (data.generales && data.generales.length) {
        this.contLicitaciones = data.generales.length;
        data.generales.forEach(licitacion => {
          //console.log(licitacion.objeto_contrato.search(this.busqueda));
          let tempNombre = licitacion.objeto_contrato.toLowerCase();
          tempNombre = this.globals.CleanedString(tempNombre);
          //console.log(tempNombre);
          //this.NombresLicitaciones.push(licitacion.objeto_contrato);
          if(tempNombre.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
            //   this.EstadosLicitaciones.push(licitacion.estado);
            // }
            // if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
            //   this.LocalidadesLicitaciones.push(licitacion.poblacion);
            // }
            // if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
            //   this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            // }
            return;
          }
          return;
          // let tempEstados = licitacion.estado.toLowerCase();
          // tempEstados = this.globals.CleanedString(tempEstados);
          // if(tempEstados.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
          //   if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
          //     var replacedval = "<strong>"+this.busqueda+"</strong>"
          //     var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
          //     licitacion.objeto_contrato = finalval;
          //     this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
          //   }
          //   // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
          //   //   this.EstadosLicitaciones.push(licitacion.estado);
          //   // }
          //   if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
          //     this.LocalidadesLicitaciones.push(licitacion.poblacion);
          //   }
          //   if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
          //     this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
          //   }
          //   return;
          // }
          let tempLocalidades = licitacion.poblacion.toLowerCase();
          tempLocalidades = this.globals.CleanedString(tempLocalidades);
          if(tempLocalidades.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
            //   this.EstadosLicitaciones.push(licitacion.estado);
            // }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
          let tempExpedientes = licitacion.num_expediente.toLowerCase();
          tempExpedientes = this.globals.CleanedString(tempExpedientes);
          if(tempExpedientes.search(this.globals.CleanedString(this.busqueda.toLowerCase())) != -1){
            if(this.NombresLicitaciones.indexOf(licitacion.objeto_contrato) === -1){
              var replacedval = "<strong>"+this.busqueda+"</strong>"
              var finalval = licitacion.objeto_contrato.replace(this.busqueda, replacedval);
              licitacion.objeto_contrato = finalval;
              this.NombresLicitaciones.push([licitacion.objeto_contrato,licitacion.id]);
            }
            // if(this.EstadosLicitaciones.indexOf(licitacion.estado) === -1){
            //   this.EstadosLicitaciones.push(licitacion.estado);
            // }
            if(this.LocalidadesLicitaciones.indexOf(licitacion.poblacion) === -1){
              this.LocalidadesLicitaciones.push(licitacion.poblacion);
            }
            if(this.ExpedienteLicitaciones.indexOf(licitacion.num_expediente) === -1){
              this.ExpedienteLicitaciones.push([licitacion.num_expediente,licitacion.id]);
            }
            return;
          }
        });
      }
      
      //ESTADOS
      if (data.estados && data.estados.length) {
        for (let item of data.estados) this.EstadosLicitaciones.push(item.estado);
      }
      //LOCALIDADES
      if (data.localidades && data.localidades.length) {
        for (let item of data.localidades) this.LocalidadesLicitaciones.push(item.localidad);
      }
      //EXPEDIENTES
      if (data.expedientes && data.expedientes.length) {
        for (let item of data.expedientes) this.ExpedienteLicitaciones.push([item.expediente, item.id]);
      }
      //ADJUDICATARIOS
      if (data.adjudicatarios && data.adjudicatarios.length) {
        for (let item of data.adjudicatarios) this.AdjudicatariosLicitaciones.push(item.adjudicatario);
      }
    });
    
    this.globals.Api('/licitaciones-busqueda', {
      filtro: this.busqueda, 
      estado: 'ADJUDICADA (ADJ)', 
      tipo: 'buscador-adjudicaciones',
      modalbuscador: true,
      limitresultados: 4
    }).subscribe(data => {
      this.AdjudicatariosLista = [];
      if (!data || !data.adjudicatarios || !data.adjudicatarios.length) return;
      //ADJUDICATARIOS
      if (data.adjudicatarios && data.adjudicatarios.length) {
        for (let item of data.adjudicatarios) this.AdjudicatariosLista.push(item.adjudicatario);
      }
    });
  }
  EjecutarQueryFiltersOrdersAdjudicaciones() {
    this.globals.Api('/licitaciones-busqueda', {localidad: '', estado: 'ADJUDICADA (ADJ)',tipo: 'buscador-adjudicaciones', filtro: this.busqueda, orden: this.filtros.orden}).subscribe(data => {
      $('#content-loading-2').hide();
      if (!data || !data.length){
        this.licitaciones = [];
        $('#no-resultados').show();
        $('#filter-search').val('');
        this.NombresLicitaciones = [];
        this.EstadosLicitaciones = [];
        this.LocalidadesLicitaciones = [];
        this.ExpedienteLicitaciones = [];
        return;
      } 
      this.licitaciones = data;
      this.licitaciones.forEach(element => {
        element.presupuesto_base_conimpuestos = new Intl.NumberFormat().format(element.presupuesto_base_conimpuestos);
      });
      //this.globals.SaveData(data, 'licitaciones-filtradas');
      this.appref.tick();
      $('#filter-search').val('');
      this.NombresLicitaciones = [];
      this.EstadosLicitaciones = [];
      this.LocalidadesLicitaciones = [];
      this.ExpedienteLicitaciones = [];
      $('#title-content-licitaciones-cards').text('Filtrando por:');
      $('#no-resultados').hide();
    });
  }
  DeleteLicitacionSeguida(licitacion:any){
    swal({
      title: "¿Dejar de seguir licitación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-seguidas-del', {id: licitacion.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.CargarMisLicitacionesId();
        this.appref.tick();
      });
    });
  }
  AbrirOrganismo(organismo:any){
    this.globals.Api('/get-organismo-by-name', {nombre: organismo}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.router.navigateByUrl('/organismo/id:'+data.id);
    });
  }
  AbrirAdjudicatario(adjudicatario:any){
    this.router.navigateByUrl('/adjudicatario/nombre:'+adjudicatario);
  }
  BusquedaFullWidth(){
    $('#column-map').hide();
    $('#column-buscador').css('width','100%');
    $('#button-mostrar-mapa').show();
    if ($(window).width() > 768) {
      $('#content-licitaciones-cards').addClass('two-columns-50');
    }
  }
  MostrarMapa(){
    this.NombresLicitaciones = [];
    this.EstadosLicitaciones = [];
    this.LocalidadesLicitaciones = [];
    this.ExpedienteLicitaciones = [];
    this.OrganismosLicitaciones = [];
    this.AdjudicatariosLicitaciones = [];
    $('#column-buscador').css('width','');
    $('#button-mostrar-mapa').hide();
    $('#column-map').show();
    $('#content-licitaciones-cards').removeClass('two-columns-50');
  }
}