<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-header></app-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="container p-t-20 p-b-120">
                    <!-- <h1 class="logo-home">
                        <img src="assets/logo-isotipo.svg"> <strong>LicitaPYME</strong>
                    </h1> -->
                    <h1 style="text-align: center">Mis obras</h1>
                    <ul class="nav nav-pills" data-toggle="tabs">
                        <li class="active"><a href="#tab-licitaciones">Licitaciones seguidas</a></li>
                        <li><a href="#tab-organizaciones">Organizaciones seguidos</a></li>
                        <li><a href="#tab-empresas">Empresas seguidas</a></li>
                    </ul>
                    <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane active" id="tab-licitaciones">
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <app-buscador tipo="mis-obras"></app-buscador>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-organizaciones">
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div id="content-licitaciones-cards">
                                        <div *ngIf="!organismos || !organismos.length" class="text-center p-t-20 p-b-20">
                                            <div class="no-resultados"><i class="fa fa-exclamation-circle"></i> Aún no sigues organimos</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let item of organismos">
                                                <div class="card-licitacion organismo">
                                                    <a [routerLink]="['/organismo/id:'+item.id]">
                                                        <div class="fecha hidden-xs" style="text-align: center;"><img *ngIf="item.imagen != null" src="{{item.imagen}}" />
                                                            <img *ngIf="item.imagen == null" src="assets/imagen-defecto-organismo-white.png" /><br/>
                                                            <a class="unfollow-button" style="margin-left: 0px" (click)="DeleteOrganismoSeguido(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                                                        </div>
                                                        <div class="titulo"><b class="text-primary">{{item.nombre}}</b></div>
                                                        <div class="desc"><b>Dirección:</b> {{item.direccion}}<br/>
                                                            <b>NIF/CIF:</b> {{item.nifcif}}
                                                        </div>
                                                        <div style="margin-top: 28px;"><span style="font-weight: 600; font-size: 11px;"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.localidad}}</span>
                                                        </div>
                                                        <div class="fecha desktop-hidden text-center m-t-10">
                                                            <img *ngIf="item.imagen != null" src="{{item.imagen}}" />
                                                            <img *ngIf="item.imagen == null" src="assets/imagen-defecto-organismo-white.png" /><br/>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </a>
                                                    <div class="desktop-hidden-block text-center" style="width: 100%;">
                                                        <a class="unfollow-button" (click)="DeleteOrganismoSeguido(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-empresas">
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div id="content-licitaciones-cards">
                                        <div *ngIf="!adjudicatarios || !adjudicatarios.length" class="text-center p-t-20 p-b-20">
                                            <div class="no-resultados"><i class="fa fa-exclamation-circle"></i> Aún no sigues empresas</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let item of adjudicatarios">
                                                <div class="card-licitacion adjudicatario">
                                                    <a [routerLink]="['/adjudicatario/nombre:'+item.lote_adjudicatario]">
                                                        <div class="fecha hidden-xs" style="text-align: center;"><img *ngIf="item.imagen != null" src="{{item.imagen}}" />
                                                            <img *ngIf="item.imagen == null" src="assets/imagen-defecto-empresa.png" /><br/>
                                                            <a class="unfollow-button" style="margin-left: 0px" (click)="DeleteAdjudicatarioSeguido(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                                                        </div>
                                                        <div class="titulo"><b class="text-primary">{{item.lote_adjudicatario}}</b></div>
                                                        <!-- <div class="desc"><b>Dirección:</b> {{item.direccion}}<br/>
                                                            <b>NIF/CIF:</b> {{item.nifcif}}
                                                        </div>
                                                        <div style="margin-top: 28px;"><span style="font-weight: 600; font-size: 11px;"><i class="icofont-google-map" style="font-size: 18px;"></i>{{item.localidad}}</span>
                                                        </div> -->
                                                        <div class="fecha text-center desktop-hidden m-t-10">
                                                            <img *ngIf="item.imagen != null" src="{{item.imagen}}"/>
                                                            <img *ngIf="item.imagen == null" src="assets/imagen-defecto-empresa.png" /><br/>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </a>
                                                    <div class="desktop-hidden-block text-center" style="width: 100%;">
                                                        <a class="unfollow-button" (click)="DeleteAdjudicatarioSeguido(item)"><i class="icofont-ui-love-broken"></i> Dejar de seguir</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
        <app-footer></app-footer>
    </div>
    <!-- END Page Container -->
</div>