import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { BackendDashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { BackendMenuComponent } from './backend/controls/menu/menu.component';
import { BackendHeaderComponent } from './backend/controls/header/header.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { LicitacionesComponent } from './backend/licitaciones/licitaciones.component';
import { ModalLicitacionComponent } from './backend/controls/modal-licitacion/modal-licitacion.component';
import { DashboardComponent } from './frontend/dashboard/dashboard.component';
import { HeaderComponent } from './frontend/controls/header/header.component';
import { FooterComponent } from './frontend/controls/footer/footer.component';
import { EmpresasComponent } from './backend/empresas/empresas.component';
import { OrganismosAdjudicatariosComponent } from './backend/organismos-adjudicatarios/organismos-adjudicatarios.component';
import { BuscadorComponent } from './frontend/controls/buscador/buscador.component';
import { AdjudicacionesComponent } from './frontend/adjudicaciones/adjudicaciones.component';
import { MisObrasComponent } from './frontend/mis-obras/mis-obras.component';
import { VerLicitacionComponent } from './frontend/ver-licitacion/ver-licitacion.component';
import { VerOrganismoComponent } from './frontend/ver-organismo/ver-organismo.component';
import { PubMiPerfilComponent } from './frontend/mi-perfil/mi-perfil.component';
import { VerAdjudicatarioComponent } from './frontend/ver-adjudicatario/ver-adjudicatario.component';
import { EditLicitacionComponent } from './backend/licitaciones/edit-licitacion/edit-licitacion.component';
import { BuscadorNuevoComponent } from './frontend/controls/buscador-nuevo/buscador-nuevo.component';
import { TextoLegalComponent } from './frontend/texto-legal/texto-legal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BackendDashboardComponent,
    BackendMenuComponent,
    BackendHeaderComponent,
    ConfigAppComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    ConfiguracionComponent,
    MiPerfilComponent,
    LicitacionesComponent,
    ModalLicitacionComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    EmpresasComponent,
    OrganismosAdjudicatariosComponent,
    BuscadorComponent,
    AdjudicacionesComponent,
    MisObrasComponent,
    VerLicitacionComponent,
    VerOrganismoComponent,
    PubMiPerfilComponent,
    VerAdjudicatarioComponent,
    EditLicitacionComponent,
    BuscadorNuevoComponent,
    TextoLegalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
