import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-organismos-adjudicatarios',
  templateUrl: './organismos-adjudicatarios.component.html'
})
export class OrganismosAdjudicatariosComponent implements OnInit {
  public title:string = 'Organismos adjudicatarios';
  public empresas = [];
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
    tipo: 'adjudicatarios'
  };
  public data:any = {};
  public imgsubir:string = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(2); //No afiliados
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
  }

  CargarGrid(paginationload:boolean = true) {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando ...'
    });
    if (this.globals.GetData('adjudicatarios')) this.empresas = this.globals.GetData('adjudicatarios');
    this.globals.Api('/adjudicatarios', this.params).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || !data.length) {
        this.empresas = [];
        this.globals.SaveData([],'adjudicatarios');
        return;
      }
      this.globals.SaveData(data, 'adjudicatarios');
      this.empresas = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/adjudicatarios/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Editar(event:any, item:any) {
    event.stopPropagation();
    this.data = JSON.parse(JSON.stringify(item));
    this.data.nombre = item.lote_adjudicatario;
    $('#modal-edit-adjudicatario').modal('show');
    $('#modal-edit-adjudicatario .modal-title').html('Adj. <b>' + this.data.nombre + '</b>');
  }
  VerLicitaciones(item:any) {
    this.globals.Api('/adjudicatarios', {adjudicatario: item.lote_adjudicatario, getlicitaciones: true}).subscribe(data => {
      if (!data || !data.length) return;
      data = data[0];
      if (!data.licitaciones || !data.licitaciones.length) {
        toastr.warning('Esta empresa no tiene licitaciones asignadas', 'Sin licitaciones');
        return;
      }
      if ($.modallicitacionesshow) $.modallicitacionesshow(data.licitaciones, data.lote_adjudicatario);
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data || !data.localidad) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad;
      this.appref.tick();
    });
  }
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    }, 800);
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    if (!this.data.id || this.data.id == '') return;
    swal({
      title: "¿Borrar imagen?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/borrar-imagen-adjudicatario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }
  Guardar() {
    if (
      !this.data.nombre
      || this.data.nombre == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.email && !this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('btn-guardar', 'Enviando');
      this.globals.Api('/adjudicatarios-add', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btn-guardar');
      });
    } else {
      $.buttonloading('btn-guardar', 'Enviando');
      this.globals.Api('/adjudicatarios-edit', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
        this.CargarGrid();
      }, error => {
        $.buttoncancelloading('btn-guardar');
      });
    }
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar empresa?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/empresas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.empresas.splice(this.empresas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
