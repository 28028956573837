import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let Swiper: any;

@Component({
  selector: 'app-adjudicaciones',
  templateUrl: './adjudicaciones.component.html'
})
export class AdjudicacionesComponent implements OnInit {
  public adjudicaciones_importe = [];
  public adjudicaciones_empresa = [];
  public adjudicaciones_baja = [];
  public params:any = {
    page_index: 1,
    page_size: 16,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
    adjudicaciones: true
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    this.CargarAdjudicaciones('por-importe');
    this.CargarAdjudicaciones('por-empresa');
    this.CargarAdjudicaciones('por-baja');
  }
  CargarAdjudicaciones(tipo:string) {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando ...'
    });
    this.params.tipoadjucaciones = tipo;
    this.globals.Api('/adjudicaciones-ranking', this.params).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || !data.length) {
        if (tipo == 'por-importe') this.adjudicaciones_importe = [];
        if (tipo == 'por-empresa') this.adjudicaciones_empresa = [];
        if (tipo == 'por-baja') this.adjudicaciones_baja = [];
        return;
      }
      if (tipo == 'por-importe') this.adjudicaciones_importe = data;
      if (tipo == 'por-empresa') this.adjudicaciones_empresa = data;
      if (tipo == 'por-baja') this.adjudicaciones_baja = data;
      setTimeout(() => {
        let swiper = new Swiper('.swiper', {
          direction: 'horizontal',
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          slidesPerView: 3,
          spaceBetween: 20,
          breakpoints: {
            0: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            990: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
        });
      }, 200);
      // setTimeout(() => {
      //   var $container = $('.post-container');
      //   $container.masonry({
      //     itemSelector       : '.post-box',
      //     columnWidth        : '.post-sizer',
      //     percentPosition: true
      //   });
      // }, 200);
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  Licitacion(item:any) {
    if ($.Licitacion) $.Licitacion(item);
  }

}
