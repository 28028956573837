<div id="sidebar">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <img src="assets/logo-header-fondoazul.png" alt="">
            <!-- <span class="sidebar-nav-mini-hide m-l-10">Licita<strong>PYME</strong></span> -->
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ng-container *ngIf="globals && globals.me">
                <!-- ADMIN -->
                <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <!-- <li>
                            <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                        </li> -->
                        <li>
                            <a [routerLink]="['/licitaciones']" routerLinkActive="active"><i class="icofont-handshake-deal sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Licitaciones</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/adjudicaciones']" routerLinkActive="active"><i class="icofont-law-document sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Adjudicaciones</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/organismos-licitadores']" routerLinkActive="active"><i class="icofont-bag-alt sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Org. Licitadores</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/organismos-adjudicatarios']" routerLinkActive="active"><i class="icofont-bag-alt sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Org. Adjudicatarios</span></a>
                        </li>
                        <li>
                            <a class="sidebar-nav-menu cursor" 
                                [class.open]="rlaafiliados.isActive"
                                [class.open]="rlaafiliados.isActive"
                                >
                                <i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Afiliados</span>
                            </a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/afiliados']" routerLinkActive="active" #rlaafiliados="routerLinkActive">Todos los afiliados</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/afiliados/add']" routerLinkActive="active" #rlaafiliados="routerLinkActive"><i class="fa fa-plus"></i> Nuevo afiliado</a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ng-container>
                <!-- AFILIADO -->
                <ng-container *ngIf="globals.me.id_rol == 2">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/inicio']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a [routerLink]="['/mi-cuenta']" routerLinkActive="active"><i class="fa fa-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mi perfil</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/mis-obras']" routerLinkActive="active"><i class="icofont-handshake-deal sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mis obras</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/adjudicaciones-top']" routerLinkActive="active"><i class="icofont-law-document sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Adjudicaciones</span></a>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div id="sidebar-extra-info" class="sidebar-content sidebar-nav-mini-hide">
        <div class="text-center">
            <small>Creado por <a href="https://pecesgordos.es/" target="_blank" style="color:var(--color-primary);">Peces Gordos Estudio</a></small><br>
        </div>
    </div>
    <!-- END Sidebar Extra Info -->
</div>