import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let jvm: any;

@Component({
  selector: 'app-ver-licitacion',
  templateUrl: './ver-licitacion.component.html'
})
export class VerLicitacionComponent implements OnInit {

  public licitacion:any = {
    'num_expediente':                 '',
    'objeto_contratacion':            '',
    'organo_contratacion':            '',
    'estado':                         '',
    'presupuesto_base_sinimpuesto':   'No establecido',
    'valor_estimado':                 'No establecido',
    'tipo_contrato':                  'No establecido',
    'cpv':                            'No establecido',
    'provincia':                      'No establecido',
    'poblacion':                      'No establecido',
    'tipo_prodedimiento':             'No establecido',
    'fecha_fin':                      'No establecido',
    'documentos':                     '',
    'pliego_prescripciones_tecnicas': ''
  };
  public idOrganismo:any = '';
  public seguida:any = false;
  public ganadores:any = {
    'num_lote':                       '',
    'num_contrato':                   '',
    'lote_adjudicado':                '',
    'num_licitaciones_presentados':   '',
    'lote_importe_sinimpuestos':      '',
    'lote_importe_conimpuestos':      '',
    'lote_lugar_ejecucion':           ''
  };
  public reporte:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    App.init();
    this.cargarDatosLicitacion();
  }
  cargarDatosLicitacion(){
    const queryString = window.location.pathname;
    var idLicitacion = queryString.split('id:')[1];
    if (!idLicitacion || idLicitacion == '') {
      this.ngZone.run(() => this.router.navigateByUrl('/inicio')).then();
      return;
    }
    this.globals.Api('/licitaciones', {id: idLicitacion}).subscribe(data => {
      if (!data || !data.length) return;
      this.licitacion = data[0];
      this.idOrganismo = this.licitacion.idorganismo;
      if(this.licitacion.presupuesto_base_sinimpuesto === undefined) this.licitacion.presupuesto_base_sinimpuesto = 'No establecido';
      if(this.licitacion.presupuesto_base_sinimpuesto_format === undefined) this.licitacion.presupuesto_base_sinimpuesto_format = 'No establecido';
      if(this.licitacion.valor_estimado === undefined) this.licitacion.valor_estimado = 'No establecido';
      if(this.licitacion.valor_estimado_format === undefined) this.licitacion.valor_estimado_format = 'No establecido';
      if(this.licitacion.tipo_contrato === undefined) this.licitacion.tipo_contrato = 'No establecido';
      if(this.licitacion.cpv === null) this.licitacion.cpv = 'No establecido';
      if(this.licitacion.provincia === undefined) this.licitacion.provincia = 'No establecido';
      if(this.licitacion.poblacion === undefined) this.licitacion.poblacion = 'No establecido';
      if(this.licitacion.tipo_prodedimiento === undefined) this.licitacion.tipo_prodedimiento = 'No establecido';
      if(this.licitacion.fecha_fin === undefined) this.licitacion.fecha_fin = 'No establecida';
      if(this.licitacion.fecha_fin === null) this.licitacion.fecha_fin = 'No establecida';
      if(this.licitacion.lotes.length == 0) this.licitacion.lotes = '';
      if(this.licitacion.cambios.length == 0) this.licitacion.cambios = '';
      if(this.licitacion.documentos.length == 0) this.licitacion.documentos = '';
      this.CargarGanadores();
      // this.CargarMapaLocalidades();
      this.ComprobarSeguida();
      // this.CargarIdOrganismo();
    });
  }
  CargarIdOrganismo(){
    // this.globals.Api('/obtener-id-organismo-by-name', {nombre_organismo: this.licitacion.organo_contratacion}).subscribe(data => {
    //   if (!data || !data.length) return;
    //   this.idOrganismo = data[0].id;
    // });
  }
  CargarGanadores(){
    this.globals.Api('/licitaciones-ganadores', {id_licitacion: this.licitacion.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.ganadores = data;
    });
  }
  CargarMapaLocalidades() {
    $('.jvectormap-tip').remove();
    $("#mapa-localidades").append('<div id="map-action" style="display: none;"></div>');
    this.globals.Api('/licitaciones-mapa-ficha', {tipo: 'localidades', provincia: 'badajoz', codpostal: this.licitacion.codpostal, localidad: this.licitacion.poblacion}).subscribe(data => {
      if (!data || !data.length) return;
      let markers = [];
      let cityAreaData = [];
      for (let item of data) {
        markers.push({
          latLng: [item.coords.lat, item.coords.lon],
          name: item.localidad
        });
        cityAreaData.push(10);
      }
      
      let localidades = [];
      let map;
      let that = this;
      let container = $('.map-container');
      container.html('<div id="mapa-localidades" style="width:100%;height:400px;"></div>');
      $("#mapa-localidades").append('<div id="map-action" style="display: none;"></div>');
      $('#map-action').click(() => {
        //this.CargarMapa();
      });
      let mapa = $('#mapa-localidades').vectorMap({
        container: container,
        map: 'es_merc',
        focusOn: {
          lat: 39.2,
          lng: -6.155078983139426,
          scale: 6,
          animate: true
        },
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
              fill: '#e4e4e4',
              "fill-opacity": 0.9,
              stroke: 'none',
              "stroke-width": 0,
              "stroke-opacity": 0
          }
        },
        markersSelectable: true,
        markers: markers,
        markerStyle: {
          initial: {fill: '#e34d63'},
          selected: {fill: '#e34d63'}
        },
        series: {
          markers: [{
            attribute: 'r',
            scale: [5, 15],
            values: cityAreaData
          }]
        },
        onMarkerTipShow: function(e, el, code){
          if (!cityAreaData || !cityAreaData[code]) return;
          el.html(
            '<span class="lugar">'+el.html()+'</span>'
          );
        },
        onMarkerClick: function (event, code) {
          //that.buscadorComponent.AplicarBusquedaLocalidad(markers[code].name);
        }
      });
    });
  }
  SeguirLicitacion(){
    this.globals.Api('/licitaciones-seguir', {id_licitacion: this.licitacion.id}).subscribe(data => {
      if (!data) return;
      this.seguida = true;
    });
  }
  ComprobarSeguida(){
    this.globals.Api('/licitaciones-comprobar-seguir', {id_licitacion: this.licitacion.id}).subscribe(data => {
      if (!data || data.length == 0){
        this.seguida = false;
        return;
      }else{
        this.seguida = true;
      }
    });
  }
  DeleteLicitacionSeguida(){
    swal({
      title: "¿Dejar de seguir licitación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/licitaciones-seguidas-del', {id: this.licitacion.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.ComprobarSeguida();
        this.appref.tick();
      });
    });
  }
  AddDocReporte() {
    if (!this.reporte.documentos) this.reporte.documentos = [];
    if (this.reporte.documentos.length >= 4) {
      swal('Error', 'No se permite adjuntar más de 4 archivos', 'error');return;
    }
    let accept = 'image/*, application/*';
    let input = this.globals.FileInput(accept, event => {
      this.globals.Base64(input, base64 => {
        let extension = '.'+input.value.split('.')[input.value.split('.').length-1].toLocaleLowerCase();
        this.reporte.documentos.push({
          nombre: input.value.replace(/.*[\/\\]/, '').replace(extension, ''),
          documento: base64,
          extension: extension
        });
      });
    });
    input.click();
  }
  DelDocReporte(item:any) {
    this.reporte.documentos.splice(this.reporte.documentos.findIndex(el => el.nombre == item.nombre), 1);
  }
  EnviarReporte() {
    if (
         !this.reporte.titulo
      || !this.reporte.mensaje
      || this.reporte.titulo == ''
      || this.reporte.mensaje == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.reporte.id_licitacion = this.licitacion.id;
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Enviando ...'
    });
    this.globals.Api('/reportes-add', this.reporte).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Reporte enviado', 'Su reporte ha sido enviado correctamente. Muchas gracias', 'success');
      this.reporte = {};
      $('#modal-reporte').modal('hide');
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
}