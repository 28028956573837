import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let Chart: any;

@Component({
  selector: 'app-backend-dashboard',
  templateUrl: './dashboard.component.html'
})
export class BackendDashboardComponent implements OnInit {
  public data:any = {};
  public reporte:any = {};
  public estados_reportes:any = [];
  public paramsemail:any = {
    fecha: moment().format('YYYY-MM-DD'),
    filtro: null,
    order: null,
    dir: null,
  };
  public email_fullscreen:boolean = false;
  

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.checkrolredirect(2); //No afiliados
    if (this.globals.GetData('datos-generales')) this.data = this.globals.GetData('datos-generales');
    this.globals.Api('/datos-generales').subscribe(data => {
      if (!data) return;
      this.data = data;
      this.globals.SaveData(data, 'datos-generales');
      this.CargarReportes();
      this.CargarClicks();
      this.CargarDatosEmail();
    });
    this.globals.Api('/estados-reportes').subscribe(data => {
      if (!data || !data.length) return;
      this.estados_reportes = data;
    });
  }
  CargarReportes() {
    this.globals.Api('/reportes', {page_index: 1, page_size: 50}).subscribe(reportes => {
      if (!reportes) return;
      this.data.reportes = reportes;
      this.globals.SaveData(this.data, 'datos-generales');
    });
  }
  CargarClicks() {
    this.globals.Api('/email-clicks').subscribe(data => {
      if (!data || !data.length) return;
      this.data.email_clicks = data;
      this.globals.SaveData(this.data, 'datos-generales');
    });
    this.globals.Api('/email-clicks', {vista: 'por-tipo'}).subscribe(data => {
      if (!data) return;
      this.data.email_clicks_portipo = data;
      this.globals.SaveData(this.data, 'datos-generales');
    });
  }
  InputCargarDatosEmail() {
    clearTimeout($.IDTimeCargarDatosEmail);
    $.IDTimeCargarDatosEmail = setTimeout(() => {
      this.CargarDatosEmail();
    }, 300);
  }
  CargarDatosEmail() {
    let container = $('#chart-email').parent();
    $('#chart-email').remove();
    container.append('<canvas id="chart-email" style="max-height:100px"></canvas>');
    this.globals.Api('/email-log', this.paramsemail).subscribe(data => {
      if (!data || !data.data) {
        this.data.email_logs = null;
        this.data.email_estadisticas = null;
        return;
      }
      this.data.email_logs = data.data;
      this.data.email_estadisticas = data.estadisticas;
      this.globals.SaveData(this.data, 'datos-generales');

      setTimeout(() => {
        if ($.gridcargado) return;
        if (window.location.pathname.indexOf('dashboard') == -1) return;
        this.globals.GridOrderJS((order, dir) => {
          this.paramsemail.order = order;
          this.paramsemail.dir = dir;
          this.paramsemail.page_index = 1;
          this.CargarDatosEmail();
        });
        $.gridcargado = true;
      }, 50);

      const datachart = {
        labels: [
          'Leídos',
          'No leídos'
        ],
        datasets: [{
          label: 'Emails leídos ' + moment(this.paramsemail.fecha).format('DD/MM/YYYY'),
          data: [data.estadisticas.leidos, data.estadisticas.noleidos],
          backgroundColor: [
            '#afde5c',
            '#2F3848'
          ],
          hoverOffset: 4
        }]
      };
      const config = {
        type: 'doughnut',
        data: datachart,
      };

      const ctx = document.getElementById('chart-email');
      new Chart(ctx,  config);
    }, error => {
      this.data.email_logs = null;
      this.data.email_estadisticas = null;
    });
  }
  VerReporte(item:any) {
    if (!item) return;
    $('#modal-reporte').modal('show');
    $('#modal-reporte .modal-title').html('Reporte <b>' + item.licitacion + '</b>');
    $('#cmbestadoreporte').val(item.id_estado);
    this.reporte = item;
  }
  VerLicitacion() {
    if (!this.reporte || !this.reporte.licitacion) return;
    this.globals.Api('/licitaciones', {num_expediente: this.reporte.licitacion}).subscribe(data => {
      if (!data || !data.length) return;
      if ($.modallicitacionshow) $.modallicitacionshow(data[0]);
    });
  }
  ChangeEstadoReporte() {
    let id_estado = $('#cmbestadoreporte').val();
    let that = this;
    if (id_estado != this.reporte.id_estado) {
      swal({
        title: "¿Cambiar estado?",
        text: 'Se notificará al usuario del cambio de estado',
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-primary",
        confirmButtonText: "Sí, notificar",
        cancelButtonText: "No",
        closeOnConfirm: true
      }, function(isConfirm) {
        if (isConfirm) {
          that.globals.Api('/reporte-cambiar-estado', {id: that.reporte.id, id_estado: id_estado}).subscribe(data => {
            if (!data || data.error) {
              $('#cmbestadoreporte').val(that.reporte.id_estado);
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            swal('Estado cambiado', 'Se ha cambiado el estado correctamente', 'success');
            $('#modal-reporte').modal('hide');
            that.CargarReportes();
          }, error => {
            $('#cmbestadoreporte').val(that.reporte.id_estado);
          });
        } else {
          $('#cmbestadoreporte').val(that.reporte.id_estado);
        }
      });
    }
  }
  IrALicitacion() {
    if (!this.reporte || !this.reporte.licitacion) return;
    if (!this.reporte.adjudicacion) {
      this.ngZone.run(() => this.router.navigateByUrl('/licitaciones?exp='+this.reporte.licitacion)).then();
    } else {
      this.ngZone.run(() => this.router.navigateByUrl('/adjudicaciones?exp='+this.reporte.licitacion)).then();
    }
  }
  BorrarReporte() {
    let that = this;
    if (!this.reporte || !this.reporte.licitacion) return;
    swal({
      title: "¿Borrar reporte?",
      text: 'No se podrán deshacer los cambios',
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {
        that.globals.Api('/reportes-del', {id: that.reporte.id}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          $('#modal-reporte').modal('hide');
          that.CargarReportes();
          setTimeout(() => {
            swal('Borrado', 'Reporte borrado correctamente', 'success');
          }, 100);
        }, error => {
          swal('Error', 'Se ha producido un error', 'error');return;
        });
      }
    });
  }

}
