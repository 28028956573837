<div id="page-wrapper" class="page-loading">
    <!-- Preloader -->
    <!-- Preloader functionality (initialized in js/app.js) - pageLoading() -->
    <!-- Used only if page preloader enabled from inc/config (PHP version) or the class 'page-loading' is added in #page-wrapper element (HTML version) -->
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <!-- END Preloader -->

    <!-- Page Container -->
    <!-- In the PHP version you can set the following options from inc/config file -->
    <!--
        Available #page-container classes:

        'sidebar-light'                                 for a light main sidebar (You can add it along with any other class)

        'sidebar-visible-lg-mini'                       main sidebar condensed - Mini Navigation (> 991px)
        'sidebar-visible-lg-full'                       main sidebar full - Full Navigation (> 991px)

        'sidebar-alt-visible-lg'                        alternative sidebar visible by default (> 991px) (You can add it along with any other class)

        'header-fixed-top'                              has to be added only if the class 'navbar-fixed-top' was added on header.navbar
        'header-fixed-bottom'                           has to be added only if the class 'navbar-fixed-bottom' was added on header.navbar

        'fixed-width'                                   for a fixed width layout (can only be used with a static header/main sidebar layout)

        'enable-cookies'                                enables cookies for remembering active color theme when changed from the sidebar links (You can add it along with any other class)
    -->
    <div id="page-container" class="header-fixed-top">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-header></app-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="container p-t-20 p-b-20">
                    <!-- <h1 class="logo-home">
                        <img src="assets/logo-isotipo.svg"> <strong>LicitaPYME</strong>
                    </h1> -->
                    <div class="row row-equal-cols">
                        <div id="column-map" class="col-md-6 col-sm-12">
                            <h3 class="m-t-0">Busca por localidad en el mapa</h3>
                            <div class="map-container relative">
                                <div id="mapa-localidades" style="width:100%;"></div>
                            </div>
                            <ul class="list-group por-determinar" *ngIf="total_pordeterminar">
                                <li class="list-group-item">
                                    Localidad no determinada:<br><b>{{total_pordeterminar}} licitaciones</b>
                                </li>
                            </ul>
                        </div>
                        <div id="column-buscador" class="col-md-6 col-sm-12">
                            <!-- <app-buscador tipo="dashboard"></app-buscador> -->
                            <app-buscador-nuevo tipo="dashboard"></app-buscador-nuevo>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
        <app-footer></app-footer>
    </div>
    <!-- END Page Container -->
</div>